import { Box, Text } from '@chakra-ui/react'

interface FilePreviewProps {
  file: File
  width?: string
  height?: string
}

export default function FilePreview({
  file,
  width = '100px',
  height = '100px',
}: FilePreviewProps) {
  return (
    <Box position="relative" minWidth={width}>
      <Box
        width={width}
        height={height}
        borderRadius="md"
        overflow="hidden"
        flexShrink={0}
      >
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Text fontSize="xs" mt={1} noOfLines={1} maxW={width}>
        {file.name}
      </Text>
    </Box>
  )
}
