import { QueryClient, useMutation } from '@tanstack/react-query'
import axiosClient from '../../axios'
import { getSavedAuthData } from '../../axios/interceptor'
import { useMyProfile } from '../user/myProfile'
import { useToast } from '@chakra-ui/react'

export function useLogOut() {
  const queryClient = new QueryClient()
  const toast = useToast()

  const { data } = useMyProfile()
  const { refreshToken } = getSavedAuthData()

  const logout = useMutation({
    mutationKey: ['/auth/sign-out'],
    mutationFn: () => {
      return axiosClient.delete('/auth/sign-out', {
        data: { userId: data?.id, refreshToken },
      })
    },
  })

  if (logout.isSuccess) {
    queryClient.clear()
    localStorage.setItem('auth', JSON.stringify({}))
  }

  if (logout.isError) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return logout
}
