import { Text, Avatar, Box, Flex, HStack, IconButton } from '@chakra-ui/react'
import { useMyProfile } from '../../api/api_hooks/user/myProfile'
import { IoChatbubblesSharp, IoNotifications } from 'react-icons/io5'

export default function HomeHeader() {
  const { data: userData } = useMyProfile()
  const BASE_URL = process.env.REACT_APP_API_DOMAIN

  return (
    <Flex justifyContent="space-between" alignItems="flex-start">
      <HStack spacing={4} align="center">
        <Avatar
          size="2xl"
          name=""
          border={'4px solid white'}
          src={
            userData?.avatarFilePath
              ? `${BASE_URL}/${userData?.avatarFilePath}`
              : ''
          }
        />
        <Box>
          <Text fontSize="2xl" fontWeight={900}>
            Hello
          </Text>
          <Text fontSize="xl" fontWeight={500}>
            {userData?.contactName}
          </Text>
        </Box>
      </HStack>
      <HStack spacing={1} alignSelf="flex-start">
        <IconButton
          aria-label="Chat"
          icon={<IoChatbubblesSharp size={25} />}
          variant="ghost"
        />
        <IconButton
          aria-label="Notifications"
          icon={<IoNotifications size={25} />}
          variant="ghost"
        />
      </HStack>
    </Flex>
  )
}
