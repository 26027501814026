import { useQuery } from '@tanstack/react-query'
import axiosClient from '../../axios'
import { ISpecialty } from '../../../types/specialties'

interface LocationParams {
  latitude: number
  longitude: number
  radius: number
}

export interface UserAround {
  id: string
  avatarFilePath: string
  contactName: string
  email: string
  specialty: ISpecialty
  location: {
    type: string
    coordinates: [number, number]
  }
}

export function useGetUsersAroundPoint(params: LocationParams) {
  return useQuery<UserAround[]>({
    queryKey: ['usersAround', params],
    queryFn: async () => {
      const { data } = await axiosClient.get<UserAround[]>('/users/around', {
        params: {
          latitude: params.latitude,
          longitude: params.longitude,
          radius: params.radius,
        },
      })
      return data
    },
    enabled: !!params.latitude && !!params.longitude && !!params.radius,
  })
}
