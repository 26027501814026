import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Tag,
  Text,
} from '@chakra-ui/react'
import Document from '../../assets/svg/document'
import ImageIcon from '../../assets/svg/image'
import { ISharedDiagnostic } from '../../types/diagnostics'
import ContactHeader from './components/ContactHeader'
import { useNavigate } from 'react-router-dom'
import { useMyProfile } from '../../api/api_hooks/user/myProfile'
import ReceiverButtons from './components/ReceiverButtons'

export default function SharedCase({
  data,
  reloadReceivedSharedDiagnostics,
}: {
  data: ISharedDiagnostic
  reloadReceivedSharedDiagnostics?: () => void
}) {
  const navigation = useNavigate()
  const { data: currentUser } = useMyProfile()
  const isSender = currentUser?.id === data.patient?.doctor?.id
  const isReceiver = currentUser?.id === data.receiverDoctor?.id
  const isAccepted = data.receiverStatus === 'accepted'
  const hasAccesToWhiteboard = isSender || isAccepted
  const isExpired = data.status === 'expired'
  const isClosed = data.status === 'closed'

  const openWhiteboard = () => {
    navigation(`/patients/${data.patientId}/diagnostics/${data.id}`, {
      replace: true,
    })
  }

  return (
    <Card
      p={4}
      position="relative"
      mt={4}
      w={'100%'}
      opacity={isExpired || isClosed ? 0.6 : 1}
      filter={isExpired ? 'grayscale(1)' : 'none'}
    >
      <CardHeader p={1}>
        <ContactHeader
          key={data.id}
          sender={data.patient?.doctor}
          receiver={data.receiverDoctor}
          receiverEmail={data.receiverEmail}
        />
        <Flex gap={1} pt={1} wrap={'wrap'}>
          {data?.tags?.map((tag: any) => (
            <Tag
              key={tag.id}
              bgColor={`${tag.color}30`}
              fontFamily={'Poppins'}
              letterSpacing={'0.6px'}
              fontSize={'small'}
            >
              #{tag.name}
            </Tag>
          ))}
        </Flex>
      </CardHeader>
      <CardBody p={0}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Flex gap={2} alignItems={'center'}>
            <Document />
            <Text>{data?.title}</Text>
          </Flex>
          {hasAccesToWhiteboard && (
            <Button
              onClick={() => openWhiteboard()}
              leftIcon={<ImageIcon />}
              colorScheme="purple"
            >
              Open Whiteboard
            </Button>
          )}
          {isReceiver && (
            <ReceiverButtons
              diagnostic={data}
              reloadReceivedSharedDiagnostics={reloadReceivedSharedDiagnostics}
            />
          )}
        </Flex>
      </CardBody>
    </Card>
  )
}
