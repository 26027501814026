import { Box, IconButton, Text } from '@chakra-ui/react'
import Plus from '../../../assets/svg/plus'

interface IListUploadedCells {
  fileCells: File[]
  removeFile: (fileName: string) => void
}

export default function ListUploadedCells({
  fileCells,
  removeFile,
}: IListUploadedCells) {
  return (
    <Box
      display={'flex'}
      gap={1}
      flexDirection={'row'}
      mt={4}
      flexWrap={'wrap'}
      width={'100%'}
    >
      {fileCells.map((file) => (
        <Box
          key={file.name + file.lastModified}
          border={'1px solid #E0E0E0'}
          display={'flex'}
          borderRadius={5}
          px={1}
          alignItems={'center'}
          justifyContent={'space-between'}
          flexShrink={0}
        >
          <Box display={'flex'} gap={1}>
            <Text fontSize={'xs'}>{file.name}</Text>
            <Text fontSize={'xs'} color={'brand.lightGrayText'}>
              ({(file.size / (1024 * 1024)).toFixed(1)}Mb)
            </Text>
          </Box>
          <Box>
            <IconButton
              colorScheme=""
              aria-label="Remove Upload Cell"
              size="sm"
              onClick={() => removeFile(file.name)}
              icon={
                <Box style={{ rotate: '135deg', color: 'gray' }}>
                  <Plus />
                </Box>
              }
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
