import { Divider, Box, Heading, Flex, Text, Tag } from '@chakra-ui/react'

import { ISharedDiagnostic } from '../../../../../types/diagnostics'
import SharedDiagnosticInfoItem from './components/SharedDiagnosticInfoItem'

const SharedDiagnosticInfo = ({
  diagnosticData,
}: {
  diagnosticData: ISharedDiagnostic
}) => {
  return (
    <>
      <Divider my={4} />
      <Box mt={4}>
        <Heading fontFamily={'Poppins'} as={'p'} size="md" mb={6}>
          Request info
        </Heading>
        <Flex gap={4} direction={'column'}>
          <SharedDiagnosticInfoItem label="From">
            <Text fontSize="sm" color={'brand.darkGrayText'}>
              {diagnosticData.patient?.doctor?.contactName}
            </Text>
            <Text fontSize="sm" color={'brand.darkGrayText'}>
              {diagnosticData.patient?.doctor?.specialty?.name}
            </Text>
          </SharedDiagnosticInfoItem>

          <SharedDiagnosticInfoItem label="To">
            {diagnosticData.receiverDoctor ? (
              <>
                <Text fontSize="sm" color={'brand.darkGrayText'}>
                  Dr. {diagnosticData.receiverDoctor.contactName}
                </Text>
                <Text fontSize="sm" color={'brand.darkGrayText'}>
                  {diagnosticData.receiverDoctor.specialty?.name}
                </Text>
              </>
            ) : (
              <Text fontSize="sm" color={'brand.darkGrayText'}>
                {diagnosticData.receiverEmail}
              </Text>
            )}
          </SharedDiagnosticInfoItem>

          <SharedDiagnosticInfoItem label="Tags">
            {diagnosticData.tags?.map((tag) => (
              <Box key={tag.id} mt={1}>
                <Tag
                  key={tag.id}
                  bgColor={`${tag.color}30`}
                  fontFamily={'Poppins'}
                  letterSpacing={'0.6px'}
                  fontSize={'small'}
                >
                  #{tag.name}
                </Tag>
              </Box>
            ))}
          </SharedDiagnosticInfoItem>
        </Flex>
      </Box>
    </>
  )
}

export default SharedDiagnosticInfo
