import { FormControl, FormErrorMessage, Text } from '@chakra-ui/react'
import { Control, UseFormRegisterReturn } from 'react-hook-form'
import DataPicker from '../../DataPicker'

interface IControlInput {
  label?: string
  isInvalid?: boolean
  placeholder?: string
  errorMessage?: string
  register: UseFormRegisterReturn
  control: Control<any>
}

export default function ControlDataPicker({
  label,
  isInvalid,
  placeholder,
  errorMessage,
  register,
  control,
  ...props
}: IControlInput) {
  return (
    <FormControl
      {...props}
      isInvalid={isInvalid}
      color={'brand.lightGray'}
      mt={4}
      w={'100%'}
    >
      <Text>{label}</Text>
      <DataPicker register={register} control={control} error={isInvalid} />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
