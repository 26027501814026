import { Box } from '@chakra-ui/react'
import PatientsBox from '../../components/PatientsBox'

export default function Patients() {
  return (
    <Box
      p={{
        base: '32px 16px',
        lg: '32px 100px',
        xl: '32px 200px',
        '2xl': '32px 300px',
      }}
      h={'100%'}
    >
      <PatientsBox />
    </Box>
  )
}
