import {
  Button,
  Card,
  Grid,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import ThreeDots from '../../../../assets/svg/ThreeDots'
import CustomGridItem from './CustomGridItem'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import Trash from '../../../../assets/svg/trash'
import {
  useDeletePatient,
  usePatientsList,
} from '../../../../api/api_hooks/patients'
import EditPatientFormModal from '../../EditPatientFormModal'
import { IPatient } from '../../../../types/patients'

interface IPatientData {
  patientData: IPatient
}
export default function PatientListItem({ patientData }: IPatientData) {
  const toast = useToast()
  const location = useLocation()

  const navigation = useNavigate()
  const { mutate, isSuccess, isError } = useDeletePatient()
  const { refetch } = usePatientsList()

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  const openPatient = () => {
    navigation(`/patients/${patientData.id}${location.search}`)
  }

  const deletePatient = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    mutate(patientData.id)
  }

  if (isSuccess) {
    refetch()
  }

  if (isError) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <Card
      p={'1rem 2rem'}
      my={'0.4rem'}
      fontSize={12}
      onClick={() => openPatient()}
      height={'fit-content'}
      cursor={'pointer'}
    >
      <Grid templateColumns="repeat(5, 1fr)" gap={4}>
        <CustomGridItem>
          <Text fontSize="md" fontWeight={600}>
            <Text fontSize="md" fontWeight={600} textTransform="capitalize">
              {`${patientData.firstName} ${patientData.lastName}`}
            </Text>
          </Text>
        </CustomGridItem>
        <CustomGridItem>{patientData.gender}</CustomGridItem>
        <CustomGridItem>
          {moment(patientData?.dateOfBirth).format('DD MMM YYYY')}
          {` (${moment().diff(moment(patientData?.dateOfBirth), 'years')} y.o)`}
        </CustomGridItem>
        <CustomGridItem>{patientData.address}</CustomGridItem>
        <CustomGridItem justifyContent={'end'}>
          <Popover>
            <PopoverTrigger>
              <IconButton
                aria-label="More info"
                icon={<ThreeDots />}
                bg={''}
                onClick={(event) => handleClick(event)}
              />
            </PopoverTrigger>
            <PopoverContent w={150}>
              <PopoverBody p={0}>
                <VStack gap={0} align="stretch">
                  <EditPatientFormModal id={patientData.id} />
                  <Button
                    fontSize={14}
                    fontWeight={400}
                    leftIcon={<Trash />}
                    bg={''}
                    onClick={deletePatient}
                    justifyContent="flex-start"
                    w="100%"
                  >
                    Delete Patient
                  </Button>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </CustomGridItem>
      </Grid>
    </Card>
  )
}
