import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import axiosClient from '../axios'

export function usePatch<T = any>(
  url: string,
  options?: UseMutationOptions<T, unknown, T, unknown>
) {
  return useMutation<T, unknown, T, unknown>({
    mutationKey: [url],
    mutationFn: async (postData: T) => {
      const { data } = await axiosClient.patch(url, postData)
      return data
    },
    ...options,
  })
}
