import { Box } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { RequestFormData } from '..'
import { useState } from 'react'
import ControlInput from '../../../Forms/components/ControlInput'
import TagsDropdown from '../../../Forms/components/TagsDropdown'
import DragAndDrop from '../../../DragAndDrop'

export default function RequestDetailsStep() {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<RequestFormData>()

  const [files] = useState<File[]>(getValues('files') || [])

  return (
    <Box display="flex" flexDirection="column">
      <ControlInput
        register={{ ...register('title') }}
        label="Title"
        placeholder="Eg. Rare disease, patient XXX..."
        isInvalid={!!errors.title}
        errorMessage={errors.title?.message}
      />
      <ControlInput
        register={{ ...register('description') }}
        label="Description"
        placeholder="Eg. Woman 32 years old, traveling from XXX..."
        isInvalid={!!errors.description}
        errorMessage={errors.description?.message}
        textarea
      />
      <Box display="flex" gap={4}>
        <Box flex={1}>
          <TagsDropdown
            values={getValues('tagIds')}
            onChange={(value: number[]) => setValue('tagIds', value)}
            isInvalid={!!errors.tagIds}
            errorMessage={errors.tagIds?.message}
          />
        </Box>
        <Box flex={1}>
          <ControlInput
            register={{ ...register('category') }}
            label="Category"
            placeholder="Eg. Dermatology, Hematology"
            isInvalid={!!errors.category}
            errorMessage={errors.category?.message}
          />
        </Box>
      </Box>
      <DragAndDrop
        value={files}
        onFilesSelected={(files) => setValue('files', files)}
        isInvalid={!!errors.files}
        errorMessage={errors.files?.message}
      />
    </Box>
  )
}
