import * as yup from 'yup'

export const email = yup
  .object({
    email: yup
      .string()
      .email('Invalid email format')
      .required('Email is required'),
  })
  .required()

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .email('Invalid email format')
      .max(50, 'Email too long')
      .required('Email is required'),
    password: yup
      .string()
      .min(8, 'Password too short')
      .max(50, 'Email too long')
      .required('Password is required'),
    keepMeLogged: yup.boolean(),
  })
  .required()

// prettier-ignore
// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
const adeliFinessNumberRegExp = /^[0-9]{9}$/
const rppsNumberRegExp = /^[0-9]{11}$/

export const RegistrationSchema = yup
  .object({
    companyName: yup
      .string()
      .min(1, 'Name too short')
      .max(30, 'Name too long')
      .required('Company name is required'),
    contactName: yup
      .string()
      .min(1, 'Name too short')
      .max(30, 'Name too long')
      .required('Contact Name is required'),
    phoneNumber: yup
      .string()
      .min(7, 'Phone number too short')
      .max(15, 'Phone number too long')
      .required('Contact Name is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    specialtyId: yup.number().required('Specialty is required'),
    email: yup
      .string()
      .email('Invalid email format')
      .max(50, 'Email too long')
      .required('Email is required'),
    password: yup
      .string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .max(50, 'Password too long')
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
      })
      .required('Confirm password is required'),
  })
  .required()

const doctorReferenceNumber = {
  adeliFinessNumber: yup
    .string()
    .matches(adeliFinessNumberRegExp, 'Adeli number must be 9 digits long'),
  rppsNumber: yup
    .string()
    .matches(rppsNumberRegExp, 'RPPS number must be 11 digits long'),
}

export const MyProfile = yup
  .object({
    companyName: yup
      .string()
      .min(1, 'Name too short')
      .max(30, 'Name too long')
      .required('Company name is required'),
    contactName: yup
      .string()
      .min(1, 'Name too short')
      .max(30, 'Name too long')
      .required('Contact Name is required'),
    phoneNumber: yup
      .string()
      .min(7, 'Phone number too short')
      .max(15, 'Phone number too long')
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    email: yup
      .string()
      .email('Invalid email format')
      .max(50, 'Email too long')
      .required('Email is required'),
    specialtyId: yup.number().required('Specialty is required'),
    avatar: yup
      .mixed()
      .test('fileSize', 'The file is too large', (value: any) => {
        if (value) {
          if (!value.length) return true // attachment is optional
          return value[0].size <= 2000000
        }
      }),
    adeliFinessNumberRegExp: doctorReferenceNumber.adeliFinessNumber.optional(),
    rppsNumberRegExp: doctorReferenceNumber.rppsNumber.optional(),
  })
  .required()

export const NewPatient = yup
  .object({
    firstName: yup
      .string()
      .min(2, 'First name must be at least 2 characters')
      .max(75, 'First name must be less than 75 characters')
      .required('First name is required'),

    lastName: yup
      .string()
      .min(2, 'Last name must be at least 2 characters')
      .max(75, 'Last name must be less than 75 characters')
      .required('Last name is required'),

    birthName: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .min(2, 'Birth name must be at least 2 characters')
      .max(75, 'Birth name must be less than 75 characters')
      .optional(),

    dateOfBirth: yup
      .date()
      .max(new Date(), 'Date of birth cannot be in the future')
      .required('Date of birth is required'),

    gender: yup
      .string()
      .required('Gender is required')
      .oneOf(['male', 'female'], 'Invalid gender value'),

    address: yup
      .string()
      .min(2, 'Please enter a valid address')
      .max(250, 'Please enter a valid address')
      .required('Address is required'),

    socialSecurityNumber: yup
      .string()
      .required('Social security number is required')
      .matches(
        /^[12]\d{2}(0[1-9]|1[0-2])\d{10}$/,
        'Invalid French social security number format'
      )
      .length(15, 'Social security number must be exactly 15 digits'),

    twinRank: yup
      .number()
      .nullable()
      .transform((value) => {
        if (value === '' || value === null || isNaN(value)) return null
        return Number(value)
      })
      .oneOf([1, 2, 3, 4], 'Invalid twin rank')
      .optional(),

    phoneNumber: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .matches(phoneRegExp, 'Invalid phone number format')
      .optional(),

    confirmedPatientConsent: yup
      .boolean()
      .required('Patient consent is required')
      .oneOf([true], 'Patient consent must be confirmed'),

    fullAddress: yup.string().nullable().optional(),

    location: yup
      .object({
        type: yup.string().equals(['Point']),
        coordinates: yup.array().of(yup.number()).length(2),
      })
      .nullable()
      .optional(),
  })
  .required()

export const NewRequest = yup
  .object({
    title: yup
      .string()
      .min(2, 'Title must be at least 2 characters')
      .max(150, 'Title must be less than 150 characters')
      .required('Title is required'),
    description: yup
      .string()
      .min(2, 'Description must be at least 2 characters')
      .max(5000, 'Description must be less than 5000 characters')
      .required('Description is required'),
    category: yup
      .string()
      .min(2, 'Category must be at least 2 characters')
      .max(75, 'Category must be less than 75 characters')
      .required('Category is required'),
    tagIds: yup
      .array()
      .of(yup.number().defined())
      .min(1, 'At least one tag is required')
      .max(5, 'Maximum 5 tags allowed')
      .required('Tags are required'),
    files: yup
      .array()
      .of(yup.mixed<File>().defined())
      .min(1, 'At least one image is required')
      .required('Images are required'),
    receiverEmail: yup
      .string()
      .email('Invalid email format')
      .required('A doctor is required'),
    openWhiteBoard: yup.boolean().required(),
  })
  .required()

export const CloseRequestDoctorNumbers = yup
  .object({
    adeliFinessNumber: doctorReferenceNumber.adeliFinessNumber.required(),
    rppsNumber: doctorReferenceNumber.rppsNumber.required(),
  })
  .required()
