export enum AnalysisTypeEnum {
  MALARIA = 'malaria',
}

export interface IAnalysis {
  id: string
  createdAt: string
  updatedAt: string
}

export interface IAnalysisMalaria extends IAnalysis {
  infectedRbcCount: number
  uninfectedRbcCount: number
  estimatedParasitemiaPercent: number
}
