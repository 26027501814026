import { Flex, Spinner, Text } from '@chakra-ui/react'

const ProcessingMsg = () => {
  return (
    <Flex align={'center'} gap={2}>
      <Spinner
        thickness="2px"
        speed="0.65s"
        emptyColor="gray.200"
        size="sm"
        color={'gray.500'}
        sx={{
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
          },
        }}
      />
      <Text
        fontWeight="medium"
        position="relative"
        color={'gray.500'}
        _after={{
          content: "'...'",
          position: 'absolute',
          animation: 'dots 1.5s infinite',
        }}
        sx={{
          '@keyframes dots': {
            '0%': { content: "''" },
            '33%': { content: "'.'" },
            '66%': { content: "'..'" },
            '100%': { content: "'...'" },
          },
        }}
      >
        PROCESSING
      </Text>
    </Flex>
  )
}

export default ProcessingMsg
