import { RefObject } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs, Zoom } from 'swiper/modules'
import { IAnalysisImage } from '../../../types/diagnostic-image'
import { MemoizedDrawImage } from './DrawImage'

interface BottomSwiperProps {
  photoData: IAnalysisImage[]
  thumbsSwiper: any
  cropToggle: boolean
  setCropToggle: () => void
  zoomIn: boolean
  swiperRef: RefObject<any>
  setZoomIn: (value: boolean) => void
  showAnalysisImagesZone?: boolean
}

const BottomSwiper = ({
  photoData,
  thumbsSwiper,
  cropToggle,
  setCropToggle,
  zoomIn,
  swiperRef,
  setZoomIn,
  showAnalysisImagesZone,
}: BottomSwiperProps) => {
  const zoomOptions = {
    toggle: true,
    dragToMove: true,
  }

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      thumbs={{
        swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
      }}
      modules={[FreeMode, Navigation, Thumbs, Zoom]}
      zoom={zoomOptions}
      allowTouchMove={!cropToggle}
      ref={swiperRef}
      onZoomChange={() => {
        setZoomIn(!zoomIn)
      }}
      className="myBottomSwiper"
      autoHeight={true}
    >
      {photoData?.map((image: any) => (
        <SwiperSlide key={image.id} style={{ overflow: 'auto' }}>
          <div className={cropToggle ? 'swiper-zoom-container' : ''}>
            <MemoizedDrawImage
              image={image}
              zoomIn={zoomIn}
              cropToggle={cropToggle}
              setCropToggle={setCropToggle}
              showAnalysisImagesZone={showAnalysisImagesZone}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default BottomSwiper
