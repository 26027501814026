import React, { useEffect, useState, useCallback } from 'react'
import {
  FormControl,
  FormErrorMessage,
  Input,
  Box,
  List,
  ListItem,
  useOutsideClick,
  Text,
  FormControlProps,
} from '@chakra-ui/react'
import { IDropdownElement } from '../../types/utils'
import { DropdownGetHookReturn } from '../../api/api_hooks/types'

interface GenericDropdownProps<T extends IDropdownElement>
  extends Omit<FormControlProps, 'onChange'> {
  value: string | number
  onChange: (value: T) => void
  errorMessage?: string
  useGetHook: () => DropdownGetHookReturn<T>
  label: string
  placeholder: string
  clearValueOnSelect?: boolean
  isInvalid?: boolean
  /**
   * Function to set the elements in the parent component
   * @param elements - Elements retrieved from the useGetHook
   * @returns
   */
  setElements?: (elements: T[]) => void
}

function GenericDropdown<T extends IDropdownElement>({
  value,
  onChange,
  errorMessage,
  useGetHook,
  label,
  placeholder,
  clearValueOnSelect = false,
  isInvalid,
  setElements,
  ...props
}: GenericDropdownProps<T>): JSX.Element {
  const { result: elements, isError, error, isSuccess } = useGetHook()
  const [inputValue, setInputValue] = useState<string>('')
  const [filteredElements, setFilteredElements] = useState<T[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const ref = React.useRef<HTMLDivElement>(null)
  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  })

  useEffect(() => {
    if (!isSuccess) return
    setFilteredElements(elements)
    setElements && setElements(elements)
  }, [isSuccess, elements, setElements])

  useEffect(() => {
    const selectedElement = elements.find(
      (s: IDropdownElement) => s.id === Number(value)
    )
    if (selectedElement) {
      setInputValue(selectedElement.name)
    }
  }, [value, elements])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setInputValue(value)
      setIsOpen(true)

      const filtered = value
        ? elements.filter((element) =>
            element.name.toLowerCase().includes(value.toLowerCase())
          )
        : elements
      setFilteredElements(filtered)
    },
    [elements]
  )

  const handleFocusChange = (isFocused: boolean) => {
    const delay = isFocused ? 0 : 300
    // Delay the closing of the dropdown to allow the user to click on the dropdown
    setTimeout(() => {
      setIsOpen(isFocused)
    }, delay)
  }

  const handleSelectElement = (element: T) => {
    // If clearValueOnSelect is true, clear the input value when an element is selected
    const newInputValue = clearValueOnSelect ? '' : element.name
    setInputValue(newInputValue)
    onChange(element)
    setIsOpen(false)
    setFilteredElements(elements)
  }

  const displayError = errorMessage || (isError ? error?.message : '')

  return (
    <FormControl
      {...props}
      isInvalid={isInvalid || !!errorMessage}
      ref={ref}
      color={'brand.lightGray'}
      mt={4}
    >
      <Text color={'brand.darkGray'}>{label}</Text>
      <div
        onFocus={() => handleFocusChange(true)}
        onBlur={() => handleFocusChange(false)}
      >
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          isDisabled={!isSuccess}
          _placeholder={{ color: 'brand.lightGray' }}
          mt={1}
          backgroundColor={'white'}
          borderColor={isInvalid ? 'red.500' : 'inherit'}
          _hover={{
            borderColor: isInvalid ? 'red.500' : 'inherit',
          }}
          _focus={{
            borderColor: isInvalid ? 'red.500' : 'inherit',
            boxShadow: isInvalid ? '0 0 0 1px #E53E3E' : 'inherit',
          }}
        />
        {isOpen && filteredElements.length > 0 && (
          <Box
            position="absolute"
            w="100%"
            bg="white"
            boxShadow="md"
            borderRadius="md"
            zIndex={1000}
            maxH="200px"
            overflowY="auto"
          >
            <List>
              {filteredElements.map((element) => (
                <ListItem
                  key={element.id}
                  px={4}
                  py={2}
                  cursor="pointer"
                  _hover={{ bg: 'gray.100' }}
                  onClick={() => handleSelectElement(element)}
                >
                  {element.name}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </div>
      {displayError && <FormErrorMessage>{displayError}</FormErrorMessage>}
    </FormControl>
  )
}

export default GenericDropdown
