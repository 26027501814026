import {
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface IControlInput {
  label?: string
  isInvalid?: boolean
  placeholder?: string
  errorMessage?: string
  register?: UseFormRegisterReturn
  textarea?: boolean
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
  value?: string
  disabled?: boolean
}

export default function ControlInput({
  label,
  isInvalid,
  placeholder,
  errorMessage,
  register,
  textarea,
  onChange,
  value,
  ...props
}: IControlInput) {
  return (
    <FormControl
      {...props}
      isInvalid={isInvalid}
      color={'brand.lightGray'}
      mt={label && 4}
    >
      {label && <Text color={'brand.darkGray'}>{label}</Text>}
      {textarea ? (
        <Textarea
          {...register}
          borderRadius={8}
          placeholder={placeholder}
          _placeholder={{ color: 'brand.lightGray' }}
          background={'white'}
          mt={1}
          minHeight="150px"
          onChange={onChange}
          value={value}
        />
      ) : (
        <Input
          {...register}
          borderRadius={8}
          placeholder={placeholder}
          _placeholder={{ color: 'brand.lightGray' }}
          background={'white'}
          mt={1}
          onChange={onChange}
          value={value}
          disabled={props.disabled}
          _disabled={{ background: 'white', cursor: 'not-allowed' }}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
