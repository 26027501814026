import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  ButtonGroup,
  useToast,
  useSteps,
  Stack,
  Text,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  Stepper,
  StepSeparator,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import RequestDetailsStep from './components/RequestDetailsStep'
import DoctorSelectionStep from './components/DoctorSelectionStep'
import ReviewStep from './components/ReviewStep'
import { useState, useEffect } from 'react'
import { CheckIcon } from '@chakra-ui/icons'
import { AxiosError } from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetPatient } from '../../../api/api_hooks/patients'
import { useNewSharedDiagnostic } from '../../../api/api_hooks/shared-diagnostic'
import { NewRequest } from '../../../utils/schema'
import Plus from '../../../assets/svg/plus'

export type RequestFormData = {
  title: string
  description: string
  category: string
  tagIds: number[]
  files: File[]
  receiverEmail: string
  openWhiteBoard: boolean
}

const steps = [
  { title: 'Request Details', description: 'Enter request information' },
  { title: 'Select Doctor', description: 'Choose a doctor to send to' },
  { title: 'Review', description: 'Review and submit' },
]

export default function NewRequestModal({
  onRequestCreated,
}: {
  onRequestCreated?: () => void
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: patientData } = useGetPatient()
  const navigate = useNavigate()
  const toast = useToast()
  const { mutate: createRequest } = useNewSharedDiagnostic()
  const [receiverDoctorDisplayName, setReceiverDoctorDisplayName] = useState<
    string | null
  >(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })

  const methods = useForm<RequestFormData>({
    resolver: yupResolver<RequestFormData>(NewRequest),
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      category: '',
      tagIds: [],
      files: [],
      receiverEmail: '',
      openWhiteBoard: false,
    },
  })

  const handleClose = () => {
    methods.reset()
    setActiveStep(0)
    onClose()
  }

  const handlePrevStep = () => setActiveStep((prev) => prev - 1)

  const handleNextStep = async () => {
    const isValid = await methods.trigger(
      activeStep === 0
        ? ['title', 'description', 'category', 'tagIds', 'files']
        : ['receiverEmail']
    )

    if (isValid) {
      setActiveStep((prev) => prev + 1)
    }
  }

  const onSubmit = (data: RequestFormData) => {
    createRequest(
      {
        title: data.title,
        description: data.description,
        category: data.category,
        files: data.files,
        tagIds: data.tagIds,
        receiverEmail: data.receiverEmail,
      },
      {
        onSuccess: (response) => {
          if (data.openWhiteBoard) {
            navigate(
              `/patients/${patientData?.id}/diagnostics/${response.data.id}`
            )
          } else {
            toast({
              title: 'Request created successfully',
              status: 'success',
              position: 'top',
              duration: 5000,
              isClosable: true,
            })
          }
          if (onRequestCreated) onRequestCreated()
          handleClose()
        },
        onError: (error) => {
          const axiosError = error as AxiosError<{ message: string }>
          const errorMessage =
            axiosError.response?.data?.message ||
            axiosError.message ||
            'An unexpected error occurred'

          toast({
            title: 'Failed to create request',
            description: errorMessage,
            status: 'error',
            position: 'top',
            duration: 5000,
            isClosable: true,
          })
        },
      }
    )
  }

  useEffect(() => {
    if (searchParams.get('newRequest') === 'true') {
      onOpen()
      searchParams.delete('newRequest')
      setSearchParams(searchParams)
    }
  }, [searchParams, onOpen, setSearchParams])

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <RequestDetailsStep />
      case 1:
        return (
          <DoctorSelectionStep
            setReceiverDoctorDisplayName={setReceiverDoctorDisplayName}
          />
        )
      case 2:
        return <ReviewStep />
      default:
        return null
    }
  }

  return (
    <>
      <Button onClick={onOpen} leftIcon={<Plus />} colorScheme="purple">
        Add request
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleClose}
        size="xl"
        isCentered
      >
        <Box fontFamily={'Poppins'}>
          <ModalOverlay />
          <ModalContent minW={'70%'} borderRadius={10} px={20}>
            <ModalHeader position="relative" py={26}>
              <Box fontSize="2xl" fontWeight={600} mb={4}>
                New request for {patientData?.firstName} {patientData?.lastName}{' '}
                {receiverDoctorDisplayName
                  ? `to ${receiverDoctorDisplayName}`
                  : ''}
              </Box>
              <ModalCloseButton position="absolute" top="4" right="4" />

              <Stack>
                <Text fontSize="md" color="purple.400" fontWeight={500}>
                  Step {activeStep + 1}: {steps[activeStep].description}
                </Text>
                <Stepper size="xs" index={activeStep} colorScheme="purple">
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepIndicator>
                        <StepStatus complete={<StepIcon />} />
                      </StepIndicator>
                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </ModalHeader>
            <ModalBody>
              <FormProvider {...methods}>
                <form
                  onSubmit={(e) => {
                    // Only allow submission via the Submit button click
                    if (activeStep !== steps.length - 1) {
                      e.preventDefault()
                    } else {
                      methods.handleSubmit(onSubmit)(e)
                    }
                  }}
                >
                  <Stack spacing={2}>
                    {renderStepContent(activeStep)}

                    <ButtonGroup
                      w="100%"
                      justifyContent="space-between"
                      spacing={4}
                      my={6}
                    >
                      <Button
                        variant="outline"
                        border={'1px solid'}
                        borderColor={'brand.lightGray'}
                        color="brand.lightGrayText"
                        onClick={
                          activeStep === 0 ? handleClose : handlePrevStep
                        }
                        flex="1"
                      >
                        {activeStep === 0 ? 'Cancel' : 'Previous'}
                      </Button>
                      {activeStep === steps.length - 1 ? (
                        <Button
                          colorScheme="purple"
                          type="button"
                          flex="1"
                          leftIcon={<CheckIcon />}
                          onClick={methods.handleSubmit(onSubmit)}
                        >
                          Validate and run request
                        </Button>
                      ) : (
                        <Button
                          colorScheme="purple"
                          onClick={handleNextStep}
                          flex="1"
                        >
                          Next
                        </Button>
                      )}
                    </ButtonGroup>
                  </Stack>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </>
  )
}
