import { Box, IconButton, Flex } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useRef, useState, useEffect, ReactNode } from 'react'

interface SwipeableGalleryProps {
  children: ReactNode
  itemSpacing?: number
  arrowOffset?: string
}

export default function SwipeableGallery({
  children,
  itemSpacing = 3,
  arrowOffset = '-50px',
}: SwipeableGalleryProps) {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(true)

  useEffect(() => {
    // Check initially if right arrow should be shown
    if (scrollContainer.current) {
      setShowRightArrow(
        scrollContainer.current.scrollWidth >
          scrollContainer.current.clientWidth
      )
    }
  }, [children])

  const handleScroll = () => {
    if (scrollContainer.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current
      setShowLeftArrow(scrollLeft > 0)
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 10)
    }
  }

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainer.current) {
      const scrollAmount = 200
      const newPosition =
        direction === 'left'
          ? scrollContainer.current.scrollLeft - scrollAmount
          : scrollContainer.current.scrollLeft + scrollAmount

      scrollContainer.current.scrollTo({
        left: newPosition,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Box position="relative">
      {showLeftArrow && (
        <Box
          position="absolute"
          left={arrowOffset}
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
        >
          <IconButton
            aria-label="Scroll left"
            icon={<ChevronLeftIcon boxSize={6} />}
            onClick={() => scroll('left')}
            size="md"
            rounded="full"
            colorScheme="gray"
          />
        </Box>
      )}
      {showRightArrow && (
        <Box
          position="absolute"
          right={arrowOffset}
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
        >
          <IconButton
            aria-label="Scroll right"
            icon={<ChevronRightIcon boxSize={6} />}
            onClick={() => scroll('right')}
            size="md"
            rounded="full"
            colorScheme="gray"
          />
        </Box>
      )}
      <Box borderRadius="md" overflow="hidden">
        <Box
          ref={scrollContainer}
          overflowX="auto"
          width="100%"
          onScroll={handleScroll}
          sx={{
            '&::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          }}
          py={3}
          px={2}
        >
          <Flex gap={itemSpacing}>{children}</Flex>
        </Box>
      </Box>
    </Box>
  )
}
