import { Center, Avatar, Heading, Box, IconButton } from '@chakra-ui/react'
import ThreeDots from '../../../../../assets/svg/ThreeDots'
import { IPatient } from '../../../../../types/patients'

const Header = ({ patientData }: { patientData: IPatient }) => {
  return (
    <Center gap={2} justifyContent={'space-between'}>
      <Center>
        <Avatar size="md" name="" src={''} border={'4px solid white'} />
        <Heading
          fontFamily={'Poppins'}
          as={'p'}
          size="md"
          maxW={200}
          textTransform="capitalize"
          ml={2}
        >
          {patientData?.firstName} {patientData?.lastName}
        </Heading>
      </Center>
      <Box>
        <IconButton aria-label="More info" icon={<ThreeDots />} bg={'white'} />
      </Box>
    </Center>
  )
}

export default Header
