import { CardHeader, Flex, Text } from '@chakra-ui/react'
import Flask from '../../../assets/svg/flask'

const Header = ({ updatedAt }: { updatedAt: moment.Moment }) => {
  return (
    <CardHeader>
      <Flex direction={'row'} align={'center'} gap={2}>
        <Flask />
        <Text fontSize="xl">Malaria blood screening</Text>
      </Flex>
      <Text fontSize="md" color={'brand.lightGrayText'}>
        Updated at : {`${updatedAt.format('DD.MM.YY HH:mm')}`}
      </Text>
    </CardHeader>
  )
}

export default Header
