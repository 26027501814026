import { useState, useCallback, useRef, useEffect } from 'react'
import { Box, Input, List, ListItem, Text, Spinner } from '@chakra-ui/react'
import { useLoadScript } from '@react-google-maps/api'

const libraries: 'places'[] = ['places']

interface Location {
  lat: string
  lon: string
}

interface AddressInputProps {
  onLocationSelect?: (location: Location, address: string) => void
  onAddressSelect?: (address: string) => void
  label?: React.ReactNode
  inputData?: string
  isInvalid?: boolean
  errorMessage?: string
  /**
   * If true, only full address will be returned, no cities or streets without number
   */
  fullAddressOnly?: boolean
}

const AddressInput: React.FC<AddressInputProps> = ({
  onLocationSelect,
  onAddressSelect,
  label,
  inputData,
  isInvalid,
  errorMessage,
  fullAddressOnly = true,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
  })

  const [inputValue, setInputValue] = useState(inputData ?? '')
  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])
  const [showDropdown, setShowDropdown] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null)
  const placesService = useRef<google.maps.places.PlacesService | null>(null)

  useEffect(() => {
    if (isLoaded) {
      autocompleteService.current = new google.maps.places.AutocompleteService()
      // We need a HTML element to create PlacesService
      placesService.current = new google.maps.places.PlacesService(
        document.createElement('div')
      )
    }
  }, [isLoaded])

  useEffect(() => {
    if (inputData) {
      setInputValue(inputData)
    }
  }, [inputData])

  const debouncedFetchAddresses = useCallback(
    (query: string) => {
      // Clear previous values
      onAddressSelect && onAddressSelect('')
      onLocationSelect && onLocationSelect({ lat: '', lon: '' }, '')

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        if (query.length >= 3 && autocompleteService.current) {
          autocompleteService.current.getPlacePredictions(
            {
              input: query,
              types: fullAddressOnly ? ['street_address'] : undefined,
            },
            (predictions, status) => {
              if (
                status === google.maps.places.PlacesServiceStatus.OK &&
                predictions
              ) {
                setOptions(predictions)
                setShowDropdown(true)
              }
            }
          )
        } else {
          setOptions([])
          setShowDropdown(false)
        }
      }, 1000)
    },
    [fullAddressOnly, onAddressSelect, onLocationSelect]
  )

  const handleSelect = (placeId: string, description: string) => {
    if (placesService.current) {
      if (!onLocationSelect) {
        onAddressSelect && onAddressSelect(description)
        setInputValue(description)
        setShowDropdown(false)
        return
      }
      placesService.current.getDetails(
        {
          placeId: placeId,
          fields: ['geometry', 'formatted_address'],
        },
        (place, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            place?.geometry?.location
          ) {
            const location = {
              lat: place.geometry.location.lat().toString(),
              lon: place.geometry.location.lng().toString(),
            }
            setInputValue(description)
            setShowDropdown(false)
            onLocationSelect(location, description)
          }
        }
      )
    }
  }

  if (loadError) {
    return (
      <Box position="relative" mt={4}>
        {label && <Text color={'brand.lightGray'}>{label}</Text>}
        <Input
          isDisabled
          placeholder="Google Maps API unavailable"
          _placeholder={{ color: 'red.500' }}
          isInvalid={true}
          mt={1}
          background={'gray.100'}
        />
        <Box color="red.500" fontSize="sm" mt={1}>
          Address search is currently unavailable. Please try again later.
        </Box>
      </Box>
    )
  }

  return (
    <Box position="relative" mt={4} w="100%">
      {label && <Text>{label}</Text>}
      <Box position="relative">
        <Input
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
            debouncedFetchAddresses(e.target.value)
          }}
          placeholder={isLoaded ? 'Tap to search' : 'Loading address search...'}
          _placeholder={{ color: 'brand.lightGray' }}
          isInvalid={isInvalid}
          isDisabled={!isLoaded}
          color={'brand.lightGray'}
          mt={1}
          background={'white'}
        />
        {!isLoaded && (
          <Box
            position="absolute"
            right="10px"
            top="50%"
            transform="translateY(-50%)"
          >
            <Spinner size="sm" color="brand.purple" />
          </Box>
        )}
      </Box>
      {isInvalid && errorMessage && (
        <Box color="red.500" fontSize="sm" mt={1}>
          {errorMessage}
        </Box>
      )}
      {showDropdown && options.length > 0 && (
        <List
          position="absolute"
          w="100%"
          bg="white"
          boxShadow="md"
          borderRadius="md"
          mt={1}
          maxH="200px"
          overflowY="auto"
          zIndex={10}
          color={'brand.lightGray'}
        >
          {options.map((place) => (
            <ListItem
              key={place.place_id}
              p={2}
              _hover={{ bg: 'gray.100', cursor: 'pointer' }}
              onClick={() => handleSelect(place.place_id, place.description)}
            >
              {place.description}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  )
}

export default AddressInput
