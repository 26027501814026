import { Flex, IconButton } from '@chakra-ui/react'
import ZoomIn from '../../../../../assets/svg/zoomIn'
import ZoomOut from '../../../../../assets/svg/zoomOut'

const ZoomButtons = ({
  setZoomIn,
  swiperRef,
}: {
  setZoomIn: (value: boolean) => void
  swiperRef: React.RefObject<any>
}) => {
  const toggleZoomIn = () => {
    setZoomIn(true)
    swiperRef.current.swiper.zoom.in()
  }
  const toggleZoomOut = () => {
    setZoomIn(false)
    swiperRef.current.swiper.zoom.out()
  }
  return (
    <Flex alignItems={'end'}>
      <IconButton
        aria-label="zoom-in-btn"
        onClick={toggleZoomIn}
        icon={<ZoomIn />}
        colorScheme="purple"
        variant={'outline'}
        borderColor={'brand.lightGrayBorder'}
        borderBottomEndRadius={0}
        borderTopEndRadius={0}
        borderInlineEnd={0}
      />
      <IconButton
        aria-label="zoom-out-btn"
        onClick={toggleZoomOut}
        icon={<ZoomOut />}
        colorScheme="purple"
        variant={'outline'}
        borderColor={'brand.lightGrayBorder'}
        borderBottomStartRadius={0}
        borderTopStartRadius={0}
      />
    </Flex>
  )
}

export default ZoomButtons
