import { Box, Flex, Heading } from '@chakra-ui/react'
import Copy from '../../../../../assets/svg/copy'
import PatientInfoItem from './components/patientInfoItem'
import moment from 'moment'
import { useMemo } from 'react'
import { IPatient } from '../../../../../types/patients'

const PatientInfo = ({ patientData }: { patientData: IPatient }) => {
  const patient = useMemo<
    IPatient & { yearOld: number; dateOFBird: string; fullDateDisplay: string }
  >(() => {
    const yearOld = moment().diff(moment(patientData?.dateOfBirth), 'years')
    const dateOFBird = moment(patientData?.dateOfBirth).format('DD MMM YYYY')
    return {
      ...patientData,
      yearOld,
      dateOFBird,
      fullDateDisplay: `${dateOFBird} (${yearOld} y.o)`,
    }
  }, [patientData])

  return (
    <Box mt={4}>
      <Heading fontFamily={'Poppins'} as={'p'} size="md" mb={4}>
        Patient info
      </Heading>
      <Flex gap={4} direction={'column'}>
        <PatientInfoItem
          title="Patient ID"
          info={patient.id}
          iconCopy={<Copy />}
        />
        {patient?.birthName && (
          <PatientInfoItem title="Birth Name" info={patient.birthName} />
        )}
        <PatientInfoItem
          title="Social Security Number"
          info={patient.socialSecurityNumber}
        />
        <PatientInfoItem title="Sex" info={patient.gender} />
        <PatientInfoItem
          title="Date of birth"
          info={patient?.fullDateDisplay}
        />
        <PatientInfoItem title="Home Address" info={patient.address} />
        {patient.phoneNumber && (
          <PatientInfoItem title="Phone Number" info={patient.phoneNumber} />
        )}
      </Flex>
    </Box>
  )
}

export default PatientInfo
