import { useParams, useSearchParams } from 'react-router-dom'
import { ISharedDiagnostic } from '../../../types/diagnostics'
import { MetaAndData } from '../types'
import { useMutation } from '@tanstack/react-query'
import axiosClient from '../../axios'
import { useGet } from '../../query/useGet'
import { useGetPatient } from '../patients'
import { PagePaginations } from '../../../components/Pagination/paginations'

interface NewSharedDiagnosticsPayload {
  title: string
  description: string
  category: string
  files: File[]
  tagIds: number[]
  receiverEmail: string
}
export function useNewSharedDiagnostic() {
  const { data: patientData } = useGetPatient()
  const newSharedDiagnostic = useMutation({
    mutationKey: ['newSharedDiagnostic'],
    mutationFn: (payload: NewSharedDiagnosticsPayload) => {
      if (!patientData) {
        throw new Error('Patient not found')
      }
      const formData = new FormData()
      formData.append('patientId', patientData.id)
      payload.files.map((file) => formData.append('files', file))
      formData.append('tagIds', JSON.stringify(payload.tagIds))
      formData.append('title', payload.title)
      formData.append('description', payload.description)
      formData.append('category', payload.category)
      formData.append('receiverEmail', payload.receiverEmail)
      return axiosClient.post<ISharedDiagnostic>(`/diagnostics`, formData)
    },
  })
  return newSharedDiagnostic
}

export function useGetReceivedSharedDiagnostics() {
  const [searchParams] = useSearchParams()
  const page = searchParams.get(PagePaginations.RECEIVED_CASE_PAGE)
  const receivedDiagnostics = useGet<MetaAndData<ISharedDiagnostic[]>>(
    `diagnostics/collaboratory/required?page=${page || 1}&sortField=createdAt&sortDirection=desc&limit=5`
  )
  return receivedDiagnostics
}

export function useGetSentSharedDiagnostics() {
  const [searchParams] = useSearchParams()
  const page = searchParams.get(PagePaginations.SENT_CASE_PAGE)
  const sentDiagnostics = useGet<MetaAndData<ISharedDiagnostic[]>>(
    `diagnostics/collaboratory/sent?page=${page || 1}&sortField=createdAt&sortDirection=desc&limit=5`
  )
  return sentDiagnostics
}

export function useGetInProgressSharedDiagnostics() {
  const inProgressDiagnostics = useGet<ISharedDiagnostic[]>(
    `diagnostics/collaboratory/in-process`
  )
  return inProgressDiagnostics
}

export function useGetSharedDiagnosticById() {
  const diagnosticId = useParams().diagnosticId
  const sharedDiagnostic = useGet<ISharedDiagnostic>(
    `diagnostics/${diagnosticId}`
  )
  return sharedDiagnostic
}

export function useCloseSharedDiagnostic() {
  const diagnosticId = useParams().diagnosticId

  const closedSharedDiagnostic = useMutation({
    mutationKey: ['closeSharedDiagnostic'],
    mutationFn: () => {
      return axiosClient.patch<ISharedDiagnostic>(
        `/diagnostics/${diagnosticId}/close`
      )
    },
  })
  return closedSharedDiagnostic
}
