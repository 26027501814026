import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Flex,
  Checkbox,
  Tag,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { RequestFormData } from '..'
import { useState, useEffect } from 'react'
import { ITag } from '../../../../types/tags'
import { useGetTags } from '../../../../api/api_hooks/tags'
import SwipeableGallery from '../../../SwipeableGallery'
import FilePreview from '../../../FilePreview'

export default function ReviewStep() {
  const { watch, register } = useFormContext<RequestFormData>()
  const formData = watch()
  const [selectedTags, setSelectedTags] = useState<ITag[]>([])

  const { data: allTags } = useGetTags()

  useEffect(() => {
    if (allTags && formData.tagIds) {
      const tags = allTags.data.filter((tag) =>
        formData.tagIds.includes(tag.id)
      )
      setSelectedTags(tags)
    }
  }, [formData.tagIds, allTags])

  const disabledStyle = { color: 'brand.lightGrayText' }

  return (
    <VStack align="stretch" spacing={5}>
      <FormControl>
        <FormLabel fontWeight="medium">Title</FormLabel>
        <Input value={formData.title} isDisabled _disabled={disabledStyle} />
      </FormControl>

      <FormControl>
        <FormLabel fontWeight="medium">Description</FormLabel>
        <Textarea
          value={formData.description}
          isDisabled
          minH="100px"
          _disabled={disabledStyle}
        />
      </FormControl>

      <Flex gap={4}>
        <FormControl flex={1}>
          <FormLabel fontWeight="medium">Tags</FormLabel>
          <Box borderRadius="md" p={2} minH="40px">
            <Flex gap={1} wrap="wrap">
              {selectedTags.map((tag) => (
                <Tag
                  key={tag.id}
                  bgColor={`${tag.color}30`}
                  fontFamily={'Poppins'}
                  letterSpacing={'0.6px'}
                  fontSize={'small'}
                >
                  #{tag.name}
                </Tag>
              ))}
            </Flex>
          </Box>
        </FormControl>
        <FormControl flex={1}>
          <FormLabel fontWeight="medium">Category</FormLabel>
          <Input
            value={formData.category}
            isDisabled
            _disabled={disabledStyle}
          />
        </FormControl>
      </Flex>

      <FormControl>
        <FormLabel fontWeight="medium">Files uploaded</FormLabel>
        <SwipeableGallery>
          {formData.files.map((file, index) => (
            <FilePreview key={index} file={file} />
          ))}
        </SwipeableGallery>
      </FormControl>

      <FormControl>
        <Checkbox
          id="openWhiteBoard"
          colorScheme="green"
          {...register('openWhiteBoard')}
        >
          Open AI White Board
        </Checkbox>
      </FormControl>
    </VStack>
  )
}
