import { Navigate, createBrowserRouter } from 'react-router-dom'
import { ProtectedRouts } from './protected'
import EnterForm from '../pages/EnterPages/EnterForm'
import LoginForm from '../components/Forms/loginForm'
import RegisterForm from '../components/Forms/registerForm'
import RegisterConfirmed from '../components/Forms/registerForm/registerConfirmed'
import MyInfo from '../pages/MyInfo'
import Patients from '../pages/Patients'
import Home from '../pages/Home'
import PatientCard from '../components/PatientsBox/PatientProfile'
import PatientsList from '../components/PatientsBox/PatientsList'
import MyCollaboratory from '../components/PatientsBox/MyCollaboratory'
import NotFound from '../pages/NotFound'
import Whiteboard from '../components/WhiteBoard'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/home" replace />,
  },
  {
    element: <EnterForm />,
    children: [
      {
        path: 'login',
        element: <LoginForm />,
      },
      {
        path: 'register',
        element: <RegisterForm />,
      },
      {
        path: 'confirm-email',
        element: <RegisterConfirmed />,
      },
    ],
  },
  {
    element: <ProtectedRouts />,
    children: [
      {
        path: '/my-info',
        element: <MyInfo />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/patients',
        children: [
          {
            element: <Patients />,
            children: [
              {
                path: '',
                element: <PatientsList />,
              },
              {
                path: 'myCollaboratory',
                element: <MyCollaboratory />,
              },
            ],
          },
          {
            path: ':id',
            element: <PatientCard />,
            children: [
              {
                path: 'diagnostics/:diagnosticId',
                element: <Whiteboard />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
])
