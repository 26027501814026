import { IconButton } from '@chakra-ui/react'
import Crop from '../../../../../assets/svg/crop'

const CropButton = ({
  cropToggle,
  setCropToggle,
}: {
  cropToggle: boolean
  setCropToggle: (value: boolean) => void
}) => {
  return (
    <IconButton
      aria-label="crop-btn"
      onClick={() => setCropToggle(!cropToggle)}
      variant={cropToggle ? 'outline' : 'solid'}
      icon={<Crop />}
      colorScheme="purple"
      borderColor={'brand.lightGrayBorder'}
    />
  )
}

export default CropButton
