import {
  Avatar,
  Divider,
  Flex,
  IconButton,
  Image,
  Link,
  List,
  ListItem,
  Spacer,
} from '@chakra-ui/react'
import sideBar from '../../assets/sideBarLogo.png'
import { menuLink } from '../../utils/menuLink'
import { NavLink, Navigate } from 'react-router-dom'
import { useLogOut } from '../../api/api_hooks/auth/useLogOut'
import { useMyProfile } from '../../api/api_hooks/user/myProfile'
import Exit from '../../assets/svg/logout'

export default function SideBar() {
  const { mutate, isSuccess } = useLogOut()
  const { data: userData } = useMyProfile()
  const BASE_URL = process.env.REACT_APP_API_DOMAIN

  if (isSuccess) return <Navigate to="/login" />

  return (
    <Flex
      p={'32px 16px'}
      color={'white'}
      direction={'column'}
      height={'100%'}
      position={'sticky'}
    >
      <Link href="/">
        <Image src={sideBar} alt="" />
      </Link>
      <Divider mt={6} mb={6} />
      <List spacing={3}>
        {menuLink.map((item) => (
          <ListItem key={item.ariaLabel}>
            <NavLink aria-label={item.ariaLabel} to={item.href}>
              {({ isActive }) => (
                <IconButton
                  aria-label={item.ariaLabel}
                  icon={item.icon}
                  w={'48px'}
                  h={'48px'}
                  background={'none'}
                  sx={{
                    background: isActive ? 'white' : '',
                    path: {
                      stroke: isActive ? 'brand.purple' : 'white',
                    },
                    '&:hover': {
                      background: 'white',
                      path: {
                        stroke: 'brand.purple',
                      },
                    },
                    '::after': isActive
                      ? {
                          width: '6px',
                          content: "''",
                          position: 'absolute',
                          right: '-16px',
                          background: 'white',
                          height: '100%',
                          borderRadius: '8px 0px 0px 8px',
                        }
                      : '',
                  }}
                />
              )}
            </NavLink>
          </ListItem>
        ))}
        <ListItem onClick={() => mutate()}>
          <IconButton
            aria-label={'item.ariaLabel'}
            icon={<Exit />}
            w={'48px'}
            h={'48px'}
            background={'none'}
            sx={{
              path: {
                stroke: 'white',
              },
              _hover: {
                background: 'white',
                path: {
                  stroke: 'brand.purple',
                },
              },
            }}
          />
        </ListItem>
      </List>

      <Spacer />
      <Divider mt={6} mb={6} />
      <Avatar
        size="md"
        name=""
        src={
          userData?.avatarFilePath
            ? `${BASE_URL}/${userData?.avatarFilePath}`
            : ''
        }
      />
    </Flex>
  )
}
