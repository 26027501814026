import { IAnalysisMalaria, AnalysisTypeEnum } from './../types/ia-analysis'
import moment from 'moment'
import {
  AnalysisImageProcessEnum,
  IAnalysisImage,
  IAnalysisImageWithAnalysis,
} from '../types/diagnostic-image'

export class AnalysisMalariaReport {
  /**
   * Total number of infected RBCs (sum of all images)
   * @returns {number}
   */
  public get infectedRbcCount(): number {
    const infectedRbcCount = this.diagnosticImagesWithAnalysis.reduce(
      (acc, image) => acc + image.analysis.infectedRbcCount,
      0
    )
    return infectedRbcCount
  }

  /**
   * Total number of uninfected RBCs (sum of all images)
   * @returns {number}
   */
  public get uninfectedRbcCount(): number {
    const uninfectedRbcCount = this.diagnosticImagesWithAnalysis.reduce(
      (acc, image) => acc + image.analysis.uninfectedRbcCount,
      0
    )
    return uninfectedRbcCount
  }

  /**
   * Percentage of parasitemia from all images
   * @returns {number}
   */
  public get estimatedParasitemiaPercent(): number {
    const totalRbcCount = this.infectedRbcCount + this.uninfectedRbcCount
    const estimatedParasitemiaPercent =
      (this.infectedRbcCount / totalRbcCount) * 100
    return estimatedParasitemiaPercent
  }

  public get updatedAt(): moment.Moment {
    const allAnalysisUpdatedDate = this._diagnosticImages.map((image) =>
      moment(image.updatedAt)
    )
    const lastAnalysisUpdatedDate = moment.max(allAnalysisUpdatedDate ?? [])
    return lastAnalysisUpdatedDate
  }

  /**
   * Process status of the analysis
   * @returns {AnalysisImageProcessEnum} Sucess if all are successful, in process if any is in progress, failed if any failed
   */
  public get process(): AnalysisImageProcessEnum {
    const hasInProgress = this._diagnosticImages.some(
      (image) => image.process === AnalysisImageProcessEnum.InProgress
    )
    if (hasInProgress) {
      return AnalysisImageProcessEnum.InProgress
    }

    const hasFailed = this._diagnosticImages.some(
      (image) => image.process === AnalysisImageProcessEnum.Failed
    )
    if (hasFailed) {
      return AnalysisImageProcessEnum.Failed
    }

    return AnalysisImageProcessEnum.Success
  }

  public get totalAnalysisImagesCount(): number {
    return this._diagnosticImages.length
  }

  /**
   * Total number of analyzed cells (sum of all RBCs) from all images
   * @returns {number}
   */
  public get totalAnalyzedCells(): number {
    const totalAnalyzedCells = this.diagnosticImagesWithAnalysis.reduce(
      (acc, image) =>
        acc +
        image.analysis.infectedRbcCount +
        image.analysis.uninfectedRbcCount,
      0
    )
    return totalAnalyzedCells
  }

  private _diagnosticImages: IAnalysisImage<IAnalysisMalaria>[]

  public get diagnosticImages(): IAnalysisImage<IAnalysisMalaria>[] {
    return this._diagnosticImages
  }

  public get diagnosticImagesWithAnalysis(): IAnalysisImageWithAnalysis<IAnalysisMalaria>[] {
    return this._diagnosticImages.filter(
      (image) => image.analysis
    ) as IAnalysisImageWithAnalysis<IAnalysisMalaria>[]
  }

  public get hasResults(): boolean {
    return this.diagnosticImagesWithAnalysis.length > 0
  }

  constructor(_diagnosticImages: IAnalysisImage<IAnalysisMalaria>[]) {
    const hasEmptyAnalysis = _diagnosticImages.some(
      (image) => !image.analysisType
    )
    if (hasEmptyAnalysis) {
      throw new Error('Some images have no analysis')
    }

    const hasWrongAnalysisType = _diagnosticImages.some(
      (image) => image.analysisType !== AnalysisTypeEnum.MALARIA
    )
    if (hasWrongAnalysisType) {
      throw new Error('Some images have wrong analysis type')
    }
    this._diagnosticImages = _diagnosticImages
  }
}
