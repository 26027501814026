import { RefObject, useRef, useState } from 'react'
import { IAnalysisImage } from '../../types/diagnostic-image'
import TopSwiper from './components/TopSwiper'
import BottomSwiper from './components/BottomSwiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import './styles.css'
import ToolsButtons from './components/ToolsButtons'

interface SwiperSliderProps {
  photoData: IAnalysisImage[]
  refetchDiagnosticImages?: () => void
}

export default function SwiperSlider({
  photoData,
  refetchDiagnosticImages,
}: SwiperSliderProps) {
  const [thumbsSwiper, setThumbsSwiper] = useState('')
  const [zoomIn, setZoomIn] = useState(false)
  const [cropToggle, setCropToggle] = useState(true)
  const swiperRef: RefObject<any> = useRef(null)

  const [showAnalysisImagesZone, setShowAnalysisImagesZone] = useState(false)

  return (
    <>
      <TopSwiper photoData={photoData} setThumbsSwiper={setThumbsSwiper} />

      <BottomSwiper
        photoData={photoData}
        thumbsSwiper={thumbsSwiper}
        cropToggle={cropToggle}
        setCropToggle={() => setCropToggle(!cropToggle)}
        zoomIn={zoomIn}
        swiperRef={swiperRef}
        setZoomIn={setZoomIn}
        showAnalysisImagesZone={showAnalysisImagesZone}
      />

      <ToolsButtons
        cropToggle={cropToggle}
        setCropToggle={() => setCropToggle(!cropToggle)}
        setZoomIn={setZoomIn}
        swiperRef={swiperRef}
        photoData={photoData}
        refetchDiagnosticImages={refetchDiagnosticImages}
        showAnalysisImagesZone={showAnalysisImagesZone}
        setShowAnalysisImagesZone={() =>
          setShowAnalysisImagesZone(!showAnalysisImagesZone)
        }
      />
    </>
  )
}
