import { IAnalysisImage } from '../../types/diagnostic-image'
import { AnalysisTypeEnum } from '../../types/ia-analysis'
import AnalysisComponentFactory from './components/AnalysisComponentFactory'

interface AnalysesProps {
  diagnosticImages: IAnalysisImage[]
}

const Analyses = ({ diagnosticImages }: AnalysesProps) => {
  const imagesWithAnalysis = diagnosticImages.filter(
    (image) => image.analysisType
  )

  const imagesGroupedByAnalysisType = imagesWithAnalysis.reduce(
    (acc, image) => {
      if (!acc[image.analysisType!]) {
        acc[image.analysisType!] = []
      }
      acc[image.analysisType!].push(image)
      return acc
    },
    {} as Record<AnalysisTypeEnum, IAnalysisImage[]>
  )

  return (
    <>
      {Object.entries(imagesGroupedByAnalysisType).map(
        ([analysisType, images]) => {
          return (
            <AnalysisComponentFactory
              key={analysisType}
              diagnosticImages={images}
            />
          )
        }
      )}
    </>
  )
}

export default Analyses
