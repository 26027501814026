import { useMutation } from '@tanstack/react-query'
import axiosClient from '../../axios'
import { useGet } from '../../query/useGet'

export function useGetZoneComments<T = any>(imageZoneId?: string) {
  const path = `/image-zones/${imageZoneId}/comments`

  const getCommentsForCell = useGet<T>(path)

  const result = getCommentsForCell.data

  return { result, ...getCommentsForCell }
}

export function useAddCommentToZone() {
  const getSendCellComments = useMutation({
    gcTime: 50000,
    mutationFn: ({ imageZoneId, comment }: any) => {
      return axiosClient.post(`/image-zones/${imageZoneId}/comments`, {
        commentText: comment,
      })
    },
  })
  return getSendCellComments
}

export function useCreateImageZone() {
  const createImageZoneWithComment = useMutation({
    mutationKey: ['createImageZoneWithComment'],
    mutationFn: (data: {
      diagnosticImageId: string
      coordinates: number[]
      commentText?: string
    }) => {
      return axiosClient.post(`/image-zones`, data)
    },
  })
  return createImageZoneWithComment
}
