import React from 'react'

function Edit() {
  const uniqueId = `clip_${Math.random().toString(36).substr(2, 9)}`

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath={`url(#${uniqueId})`}>
        <path
          stroke="#344054"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.333 2a1.884 1.884 0 013.22 1.333A1.887 1.887 0 0114 4.667l-9 9-3.667 1 1-3.667 9-9z"
        ></path>
      </g>
      <defs>
        <clipPath id={uniqueId}>
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Edit
