import {
  Box,
  Checkbox,
  Highlight,
  Link,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema } from '../../../utils/schema'
import ConfirmButton from '../components/ConfirmButton'
import ControlInput from '../components/ControlInput'
import FormHead from '../components/FormHead'
import useAuthAPI from '../../../api/api_hooks/auth/useAuthAPI'
import { Navigate } from 'react-router-dom'
import ControlPassword from '../components/ControlPassword'

type Inputs = {
  email: string
  password: string
  keepMeLogged?: boolean
}

export default function LoginForm() {
  const toast = useToast()
  const { useLogin } = useAuthAPI()
  const post = useLogin()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(loginSchema),
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    post.mutateAsync(data as unknown as void).catch(() =>
      toast({
        title: 'Password or email is incorrect',
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    )
  }
  if (post.isSuccess) return <Navigate to="/home" replace />

  return (
    <Box mt={116} minW={270}>
      <FormHead
        title={'Welcome to Careflai'}
        highlightQuery="ai"
        subTitle="Log in with the data that you entered during your registration"
      />

      <Box mt={'40px'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={6}>
            <ControlInput
              register={{ ...register('email') }}
              label="Your email"
              placeholder="name@email.com"
              isInvalid={!!errors.email}
              errorMessage={errors.email && errors.email.message}
            />
            <ControlPassword
              register={{ ...register('password') }}
              label="Your password"
              placeholder="at least 8 characters"
              isInvalid={!!errors.password}
              errorMessage={errors.password && errors.password.message}
            />
            <Checkbox
              borderRadius={5}
              borderColor={'#D0D5DD'}
              size="lg"
              {...register('keepMeLogged')}
              mt={4}
            >
              Keep me logged in
            </Checkbox>
          </Box>

          <ConfirmButton />
        </form>
      </Box>

      <VStack spacing={1} mt={'16px'}>
        <Link href="#">Forgot password ?</Link>
        <Link href="register">
          <Highlight query="Register" styles={{ color: 'brand.purple' }}>
            Don’t have an account? Register
          </Highlight>
        </Link>
      </VStack>
    </Box>
  )
}
