import { extendTheme } from '@chakra-ui/react'
import './index.css'

export const theme = extendTheme({
  fonts: {
    body: 'Space Grotesk, Poppins',
    heading: 'Space Grotesk,  Poppins',
  },
  colors: {
    brand: {
      blue: '#2c73fd',

      purple: '#6d3cfe',
      opacityPurple: '#F9EEFE',

      extraLightGrayBg: '#F7FAFC',
      lightGray: '#B3B3B3',
      lightGrayText: '#737A86',
      lightGrayBorder: '#D0D5DD',
      darkGray: '#2D3748',
      darkGrayBg: '#344054',
      gray: '#A5A5A5',

      sideBarBlack: '#030303',

      lightWhite: '#F7FAFC',
    },
    purple: {
      50: 'rgba(228, 218, 252, 0.3)',
      100: '#DBCDFC',
      200: '#CFBEFA',
      300: '#B9A0F8',
      400: '#936EF6',
      500: '#6d3cfe', // main
      600: '#6739EE',
      700: '#6035DF',
      800: '#5931CF',
      900: '#522DBF',
    },
  },
})
