import { Box, Checkbox, useToast } from '@chakra-ui/react'
import ControlInput from '../components/ControlInput'
import ConfirmButton from '../components/ConfirmButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import ControlSelect from '../components/ControlSelect'
import ControlDataPicker from '../components/ControlDataPicker'
import { NewPatient } from '../../../utils/schema'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  useCreatePatient,
  useEditPatient,
  useGetPatient,
  usePatientsList,
} from '../../../api/api_hooks/patients'
import { useEffect } from 'react'
import AddressInput from '../components/AddressInput'

type Inputs = {
  firstName: string
  lastName: string
  birthName?: string | null
  dateOfBirth: Date
  gender: string
  address: string
  socialSecurityNumber: string
  twinRank?: number | null
  phoneNumber?: string | null
  confirmedPatientConsent: boolean
}

export default function PatientForm({ id, onClose }: any) {
  const toast = useToast()

  const {
    mutate: mutateEditPatient,
    isSuccess: isSuccessEditPatient,
    data: patchData,
  } = useEditPatient()
  const { mutate, isSuccess, data, isError } = useCreatePatient()
  const { refetch } = usePatientsList()
  const { data: patientData, refetch: getPatientAgain } = useGetPatient(id)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<Inputs>({
    resolver: yupResolver(NewPatient),
  })
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    patientData
      ? mutateEditPatient({ id: patientData.id, data })
      : mutate(data as unknown as void)
  }

  useEffect(() => {
    if (patientData) {
      setValue('gender', patientData.gender)
      setValue('dateOfBirth', new Date(Date.parse(patientData.dateOfBirth)))
      setValue('address', patientData.address)
      setValue('socialSecurityNumber', patientData.socialSecurityNumber)
      setValue('twinRank', patientData.twinRank)
      setValue('phoneNumber', patientData.phoneNumber)
      setValue('firstName', patientData.firstName)
      setValue('lastName', patientData.lastName)
      setValue('birthName', patientData.birthName)
      setValue('confirmedPatientConsent', patientData.confirmedPatientConsent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData])

  useEffect(() => {
    if (isSuccess || isSuccessEditPatient) {
      refetch()
      getPatientAgain()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isSuccessEditPatient])

  useEffect(() => {
    if (data || patchData) {
      toast({
        title: `${id ? 'Update' : 'Create'} patient - success`,
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, patchData])

  if (isError) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <>
      <Box minW={270} padding={{ base: '0px', lg: '0px 100px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" gap={4} mb={4}>
            <ControlInput
              register={{ ...register('firstName') }}
              label="First Name"
              placeholder="First Name"
              isInvalid={!!errors.firstName}
              errorMessage={errors.firstName && errors.firstName.message}
            />
            <ControlInput
              register={{ ...register('lastName') }}
              label="Last Name"
              placeholder="Last Name"
              isInvalid={!!errors.lastName}
              errorMessage={errors.lastName && errors.lastName.message}
            />
          </Box>
          <Box display="flex" gap={4} mb={4}>
            <ControlInput
              register={{ ...register('birthName') }}
              label="Birth Name (optional)"
              placeholder="Birth Name"
              isInvalid={!!errors.birthName}
              errorMessage={errors.birthName && errors.birthName.message}
            />
            <ControlDataPicker
              register={{ ...register('dateOfBirth') }}
              label="Date of Birth"
              placeholder="Choose date"
              isInvalid={!!errors.dateOfBirth}
              errorMessage={errors.dateOfBirth && errors.dateOfBirth.message}
              control={control}
            />
          </Box>
          <ControlInput
            register={{ ...register('socialSecurityNumber') }}
            label="Social Security Number"
            placeholder="XXXXXXXXXXXXXXX"
            isInvalid={!!errors.socialSecurityNumber}
            errorMessage={
              errors.socialSecurityNumber && errors.socialSecurityNumber.message
            }
          />
          <Box mt={4}>
            <AddressInput
              onAddressSelect={(address) => {
                setValue('address', address)
              }}
              label="Full Address"
              inputData={patientData?.address}
              isInvalid={!!errors.address}
              errorMessage={errors.address && errors.address.message}
              fullAddressOnly={true}
            />
          </Box>
          <ControlInput
            register={{ ...register('phoneNumber') }}
            label="Phone Number (optional)"
            placeholder="+33"
            isInvalid={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber && errors.phoneNumber.message}
          />
          <Box display="flex" gap={4} mb={4}>
            <ControlSelect
              register={{ ...register('twinRank') }}
              label="Twin Rank (optional)"
              placeholder="Select twin rank"
              isInvalid={!!errors.twinRank}
              errorMessage={errors.twinRank && errors.twinRank.message}
              options={['1', '2', '3', '4']}
            />
            <ControlSelect
              register={{ ...register('gender') }}
              label="Gender"
              placeholder="Select patient gender"
              isInvalid={!!errors.gender}
              errorMessage={errors.gender && errors.gender.message}
              options={['male', 'female']}
            />
          </Box>
          <Box display="flex" gap={4} mt={8}>
            <Box display="flex" alignItems="center" w="60%">
              <Checkbox
                {...register('confirmedPatientConsent')}
                id="confirmedPatientConsent"
                colorScheme="green"
              >
                Continuing, you confirm that you have received the patient's
                consent.
              </Checkbox>
              {errors.confirmedPatientConsent && (
                <Box color="red.500" fontSize="sm" ml={2}>
                  {errors.confirmedPatientConsent.message}
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              w="40%"
            >
              <ConfirmButton
                text={`${id ? 'Update' : 'Create'} Patient`}
                width="100%"
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  )
}
