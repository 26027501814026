import React, { useMemo } from 'react'
import { GoogleMap, OverlayView, useLoadScript } from '@react-google-maps/api'
import { Box, Spinner, Text } from '@chakra-ui/react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { UserAround } from '../../../../../api/api_hooks/user/useGetUsersAroundPoint'

interface SearchParams {
  location: {
    lat: string
    lon: string
  }
  radius: number
}

interface MapViewProps {
  searchParams: SearchParams
  users: UserAround[]
  selectedUser?: UserAround | null
}

const MapView: React.FC<MapViewProps> = ({
  searchParams,
  users,
  selectedUser,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: ['places'],
  })

  const center = useMemo(() => {
    const lat = parseFloat(searchParams.location.lat)
    const lon = parseFloat(searchParams.location.lon)

    if (selectedUser) {
      return {
        lat: selectedUser.location.coordinates[1],
        lng: selectedUser.location.coordinates[0],
      }
    }

    // By default, center the map on Paris
    return !isNaN(lat) && !isNaN(lon)
      ? { lat, lng: lon }
      : { lat: 48.8566, lng: 2.3522 }
  }, [searchParams.location.lat, searchParams.location.lon, selectedUser])

  const mapOptions = {
    disableDefaultUI: true, // Disables default UI elements (zoom, StreetView, etc.)
    mapTypeControl: false, // Disables map type controls (normal, satellite, etc.)
    streetViewControl: false, // Disables StreetView control
    fullscreenControl: false, // Disables fullscreen control
  }

  if (loadError)
    return (
      <Box color="red.500" fontSize="sm" mt={4}>
        Map is currently unavailable. Please try again later.
      </Box>
    )

  if (!isLoaded)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="400px"
      >
        <Spinner size="lg" color="brand.purple" />
        <Text ml={4} color="brand.lightGray">
          Loading map...
        </Text>
      </Box>
    )

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '250px',
      }}
      center={center}
      zoom={12}
      options={mapOptions}
    >
      {users.map((user) => {
        const userLat = user.location.coordinates[1]
        const userLng = user.location.coordinates[0]
        const isSelected = user.id === selectedUser?.id

        return (
          <OverlayView
            key={user.email}
            position={{ lat: userLat, lng: userLng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <Box
              color={isSelected ? 'red.500' : 'gray.600'}
              fontSize={isSelected ? '2.5rem' : '2rem'}
              transform="translate(-50%, -100%)"
            >
              <FaMapMarkerAlt />
            </Box>
          </OverlayView>
        )
      })}
    </GoogleMap>
  )
}

export default MapView
