import { Button, ButtonProps } from '@chakra-ui/react'

interface IConfirmButton extends ButtonProps {
  text?: string
}

export default function ConfirmButton({
  text = 'Log In',
  ...props
}: IConfirmButton) {
  return (
    <Button {...props} w={'100%'} colorScheme="purple" type="submit">
      {text}
    </Button>
  )
}
