import { Box, Text, Tooltip } from '@chakra-ui/react'
import { IImageZone } from '../../../../types/image-zone'
import { useState } from 'react'
import CommentComponent from '../../../CommentComponent'
import { theme } from '../../../../theme/theme'
import CommentBubble from '../../../../assets/svg/commentBubble'
import { useGetSharedDiagnosticById } from '../../../../api/api_hooks/shared-diagnostic'

interface ImageZoneProps {
  imageZone: IImageZone
  scaleFactor: number
  onCommentCreated?: () => void
}

function ImageZone({
  imageZone,
  scaleFactor,
  onCommentCreated,
}: ImageZoneProps) {
  const { width, height, left, top } = imageZone
  const x1 = left * scaleFactor
  const y1 = top * scaleFactor
  const scaledWidth = width * scaleFactor
  const scaledHeight = height * scaleFactor
  const hasComments = imageZone.commentsCount > 0
  const readOnly = useGetSharedDiagnosticById().data?.status !== 'in_process'
  const isIaAnalysisZone = !imageZone.isAddedBySelf
  const isClickable = hasComments || !readOnly
  function zoneColor() {
    if (isIaAnalysisZone) {
      return '#f87171'
    }
    if (hasComments) {
      return theme.colors.brand.purple
    }
    return theme.colors.brand.lightGrayBorder
  }

  const [isCommentsOpen, setIsCommentsOpen] = useState(false)

  const borderThickness = hasComments && !isCommentsOpen ? 0 : 1
  const hasCommentsAndIsClosed = hasComments && !isCommentsOpen
  const bubbleSize = 24

  const openOptions = () => {
    if (!isClickable) return
    setIsCommentsOpen(!isCommentsOpen)
  }

  return (
    <>
      <Tooltip
        placement="auto"
        label={
          <CommentComponent
            diagnosticImageId={imageZone.diagnosticImageId}
            imageZoneId={imageZone.id}
            onClose={() => setIsCommentsOpen(false)}
            onCommentCreated={onCommentCreated}
            imageZoneUrl={imageZone.mediaUrl}
          />
        }
        isOpen={isCommentsOpen}
        pointerEvents={'all'}
        borderRadius={'2xl'}
        px={4}
        pt={0}
        pb={4}
        hasArrow
        arrowSize={14}
      >
        <Box
          key={imageZone.id}
          position={'absolute'}
          left={`${hasCommentsAndIsClosed ? x1 + (scaledWidth / 2 - bubbleSize / 4) : x1}px`}
          top={`${hasCommentsAndIsClosed ? y1 + (scaledHeight / 2 - bubbleSize / 4) : y1}px`}
          w={`${hasCommentsAndIsClosed ? bubbleSize / 2 : scaledWidth}px`}
          h={`${hasCommentsAndIsClosed ? bubbleSize / 2 : scaledHeight}px`}
          border={`${borderThickness}px solid ${zoneColor()}`}
          transition={'border 0.3s ease-in'}
          borderRadius={'sm'}
          boxShadow={
            hasCommentsAndIsClosed ? undefined : '0 0 5px rgba(0, 0, 0, 0.5)'
          }
          cursor={'pointer'}
          onClick={() => openOptions()}
        >
          {hasComments && (
            <Box
              position={'absolute'}
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              padding="4px"
              borderRadius="full"
              color={'white'}
              opacity={isCommentsOpen ? 0 : 1}
              transition={'opacity 0.3s ease-in'}
              filter={
                isCommentsOpen
                  ? undefined
                  : 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.5))'
              }
            >
              <CommentBubble
                fill={isIaAnalysisZone ? '#f87171' : theme.colors.brand.purple}
                height={bubbleSize}
                width={bubbleSize}
                strokeWith={1}
              />
              <Text
                marginRight={1}
                position={'absolute'}
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                color={'white'}
                fontSize={'sm'}
              >
                {imageZone.commentsCount}
              </Text>
            </Box>
          )}
        </Box>
      </Tooltip>
    </>
  )
}

export default ImageZone
