import {
  Button,
  Flex,
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import ConfirmButton from '../../Forms/components/ConfirmButton'
import { ISharedDiagnostic } from '../../../types/diagnostics'
import { useRespondToInvitationSharedDiagnostic } from '../../../api/api_hooks/invitations'

interface IReceiverButtonsProps {
  diagnostic: ISharedDiagnostic
  reloadReceivedSharedDiagnostics?: () => void
}

const ReceiverButtons = ({
  diagnostic,
  reloadReceivedSharedDiagnostics,
}: IReceiverButtonsProps) => {
  const { mutateAsync: respondToCollaboration } =
    useRespondToInvitationSharedDiagnostic(diagnostic.id)

  const [pendingAction, setPendingAction] = useState<
    ISharedDiagnostic['receiverStatus'] | null
  >(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const confirmButtonColor = pendingAction === 'accepted' ? 'purple' : 'red'
  const acceptText =
    pendingAction === 'accepted' ? 'Yes, accept' : 'Yes, decline'

  const respondToInvite = async (
    status: ISharedDiagnostic['receiverStatus']
  ) => {
    await respondToCollaboration(status)
    reloadReceivedSharedDiagnostics?.()
  }

  const handleConfirm = async () => {
    if (pendingAction) {
      await respondToInvite(pendingAction)
      setPendingAction(null)
      onClose()
    }
  }

  const openConfirmation = (action: ISharedDiagnostic['receiverStatus']) => {
    setPendingAction(action)
    onOpen()
  }

  const RespondButtons = () => {
    return (
      <Flex gap={2}>
        <Button
          variant="outline"
          border={'1px solid'}
          borderColor={'brand.lightGray'}
          color="brand.lightGrayText"
          w={'100%'}
          onClick={() => openConfirmation('declined')}
        >
          Decline
        </Button>
        <ConfirmButton
          text="Accept"
          w={'100%'}
          onClick={() => openConfirmation('accepted')}
        />
      </Flex>
    )
  }

  const ExpiredMessage = () => {
    return (
      <Flex gap={2}>
        <Text>Expired</Text>
      </Flex>
    )
  }

  const DeclinedMessage = () => {
    return (
      <Flex gap={2}>
        <Text>Declined</Text>
      </Flex>
    )
  }

  return (
    <>
      {diagnostic.receiverStatus === 'pending' && <RespondButtons />}
      {diagnostic.receiverStatus === 'expired' && <ExpiredMessage />}
      {diagnostic.receiverStatus === 'declined' && <DeclinedMessage />}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setPendingAction(null)
          onClose()
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm action
            </AlertDialogHeader>

            <AlertDialogBody>
              {pendingAction === 'accepted'
                ? 'Accept the invitation?'
                : 'Are you sure you want to decline the invitation?'}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setPendingAction(null)
                  onClose()
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme={confirmButtonColor}
                onClick={handleConfirm}
                ml={3}
              >
                {acceptText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default ReceiverButtons
