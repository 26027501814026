import { Box, Heading } from '@chakra-ui/react'
import ProfileForm from '../../components/Forms/profileForm'

export default function MyInfo() {
  return (
    <Box
      p={{
        base: '32px 16px',
        lg: '32px 100px',
        xl: '32px 200px',
        '2xl': '32px 300px',
      }}
    >
      <Heading as="h2" size="xl">
        My Profile
      </Heading>
      <ProfileForm />
    </Box>
  )
}
