import { Flex, Grid } from '@chakra-ui/react'
import PatientListItem from './components/PatientListItem'
import CustomGridItem from './components/CustomGridItem'
import PatientFormModal from '../PatientFormModal'
import { usePatientsList } from '../../../api/api_hooks/patients'
import Pagination from '../../Pagination'

export default function PatientsList() {
  const { data } = usePatientsList()

  return (
    <>
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
        p={'1rem 2rem'}
      >
        <CustomGridItem color={'brand.lightGrayText'}>Full Name</CustomGridItem>
        <CustomGridItem color={'brand.lightGrayText'}>Gender</CustomGridItem>
        <CustomGridItem color={'brand.lightGrayText'}>
          Date of birth
        </CustomGridItem>
        <CustomGridItem color={'brand.lightGrayText'}>Address</CustomGridItem>
        <CustomGridItem justifyItems={'end'}>
          <PatientFormModal />
        </CustomGridItem>
      </Grid>
      <Flex direction={'column'} height={'100%'} overflowY={'auto'}>
        {data?.data?.map((item: any, i: number) => (
          <PatientListItem
            key={item.id}
            patientData={{ id: item.id, ...item }}
          />
        ))}
      </Flex>
      <Pagination data={data} pageName="patientsPage" />
    </>
  )
}
