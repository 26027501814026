import { Heading, Flex, VStack, Box, Text, Center } from '@chakra-ui/react'
import PatientFormModal from '../PatientsBox/PatientFormModal'
import PatientListItem from '../PatientsBox/PatientsList/components/PatientListItem'
import { useLastManagedPatientsList } from '../../api/api_hooks/patients'

export default function LastManagedPatients() {
  const { result: patients, isLoading } = useLastManagedPatientsList(5)

  return (
    <VStack align="stretch" spacing={6} height="100%" overflow="hidden" py={4}>
      <Flex alignItems="center" justify="space-between" flexShrink={0}>
        <Heading size="md">Last Managed Patients</Heading>
        <PatientFormModal />
      </Flex>

      <Box flex={1} overflowY="auto">
        {isLoading ? (
          <Center h="100%" w="100%">
            <Text>Loading...</Text>
          </Center>
        ) : patients.length ? (
          <Flex direction="column">
            {patients.map((patient) => (
              <PatientListItem key={patient.id} patientData={patient} />
            ))}
          </Flex>
        ) : (
          <Center h="100%">
            <Text color="gray.500">No patients found</Text>
          </Center>
        )}
      </Box>
    </VStack>
  )
}
