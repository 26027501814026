import { useMutation } from '@tanstack/react-query'
import axiosClient from '../../axios'
import { AnalysisTypeEnum } from '../../../types/ia-analysis'
import { IAnalysisImage } from '../../../types/diagnostic-image'

export const usePostAnalysis = () => {
  const postAnalaysis = useMutation({
    mutationKey: ['postAnalysis'],
    mutationFn: async ({
      diagnosticImageId,
      analysisType,
    }: {
      diagnosticImageId: IAnalysisImage['id']
      analysisType: AnalysisTypeEnum
    }) => {
      const res = await axiosClient.post<void>(
        `/analysis/${diagnosticImageId}/${analysisType}`
      )
      return res
    },
  })
  return postAnalaysis
}
