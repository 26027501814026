import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import axiosClient from '../axios'
import { getSavedAuthData } from '../axios/interceptor'

export function useGet<T = any>(url: string, config?: UseQueryOptions<T>) {
  const { userId } = getSavedAuthData()

  const validPath =
    !url.includes('//') && !url.includes('/undefined') && !url.includes('/null')

  return useQuery<T>({
    enabled: validPath && (config?.enabled ?? true),
    staleTime: 50000,
    queryKey: [url + userId],
    retry: 1,
    queryFn: async () => {
      const { data } = await axiosClient.get(url)
      return data
    },
    ...config,
  })
}
