import { useQuery } from '@tanstack/react-query'
import { publicAxios } from '../../axios'
import { AxiosResponse } from 'axios'
import { ITag } from '../../../types/tags'
import { DropdownGetHookReturn } from '../types'

export function useGetTags(): DropdownGetHookReturn<ITag> {
  const path = '/tags'

  const getTags = useQuery<AxiosResponse<ITag[]>>({
    staleTime: 50000,
    queryKey: [path],
    retry: 1,
    queryFn: async () => {
      const response = await publicAxios.get(path)
      return response
    },
  })
  const result = (getTags.data?.data ?? []) as ITag[]

  return { result, ...getTags }
}
