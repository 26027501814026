import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import ConfirmButton from '../../Forms/components/ConfirmButton'
import { ISharedDiagnostic } from '../../../types/diagnostics'
import { InfoOutlineIcon } from '@chakra-ui/icons'

interface HeaderWhiteBoardCardProps {
  sharedDiagnostic: ISharedDiagnostic
  onOpen: () => void
}

const HeaderWhiteBoardCard = ({
  sharedDiagnostic,
  onOpen,
}: HeaderWhiteBoardCardProps) => {
  const isInProcess = sharedDiagnostic.status === 'in_process'
  const isClosed = sharedDiagnostic.status === 'closed'
  const isExpired = sharedDiagnostic.status === 'expired'
  const pendingInvitation = sharedDiagnostic.receiverStatus === 'pending'
  return (
    <>
      <Flex justify={'space-between'} align={'center'}>
        <Box>
          {!isInProcess && (
            <Flex align={'center'} gap={2} mb={2}>
              <InfoOutlineIcon color={'brand.lightGrayText'} />
              <Text
                fontSize={'md'}
                color={'brand.lightGrayText'}
                textAlign={'center'}
                fontStyle={'italic'}
              >
                {isClosed
                  ? 'This case was closed and cannot be modified.'
                  : isExpired
                    ? "This case has expired as the invitation wasn't accepted"
                    : null}
              </Text>
            </Flex>
          )}
          <Text fontWeight={'medium'} fontSize={'x-large'}>
            White Board
          </Text>
        </Box>
        <Box w={'20%'}>
          {isInProcess && (
            <Tooltip
              isDisabled={!pendingInvitation}
              hasArrow
              label="This case cannot be finalized until the invitation is accepted"
              placement="top"
              bg="gray.700"
            >
              <Box width="100%">
                <ConfirmButton
                  text="Finalize"
                  onClick={onOpen}
                  isDisabled={pendingInvitation}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      </Flex>
      <Accordion allowToggle>
        <AccordionItem border="none" my={2}>
          <AccordionButton p={0} _hover={{ bg: 'transparent' }}>
            <Text fontSize={'md'} fontWeight={'medium'}>
              Description of the case
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text fontSize={'sm'} color={'gray.600'}>
              {sharedDiagnostic.description}
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default HeaderWhiteBoardCard
