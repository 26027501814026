import { useMemo } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useMyProfile } from '../../../api/api_hooks/user/myProfile'
import { IUser } from '../../../types/users'

interface IContactHeaderProps {
  sender: IUser | undefined
  receiver: IUser | undefined
  receiverEmail: string | undefined
}

const ContactHeader = ({
  receiver,
  receiverEmail,
  sender,
}: IContactHeaderProps) => {
  const { data: currentUser } = useMyProfile()

  const receiverInfo = useMemo(() => {
    if (receiver) {
      return {
        contactName: receiver.contactName,
        specialty: receiver.specialty?.name,
      }
    }
    return {
      contactName: receiverEmail,
      specialty: null,
    }
  }, [receiver, receiverEmail])

  const isSender = useMemo(() => {
    return currentUser?.id === sender?.id
  }, [currentUser?.id, sender?.id])

  return (
    <Flex alignItems={'center'} gap={2}>
      {isSender ? (
        <Text fontWeight={600} fontSize={'lg'}>
          {`To ${receiverInfo?.contactName} `}
          {receiverInfo?.specialty ? (
            <>
              {' - '}
              {receiverInfo?.specialty}
            </>
          ) : (
            <Text fontStyle={'italic'} display={'inline'} fontWeight={'normal'}>
              {' '}
              (Invited user)
            </Text>
          )}
        </Text>
      ) : (
        <Text fontWeight={600} fontSize={'lg'}>
          {`From ${sender?.contactName} `}
          {' - '}
          {sender?.specialty?.name}
        </Text>
      )}
    </Flex>
  )
}

export default ContactHeader
