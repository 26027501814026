import { Box } from '@chakra-ui/react'
import SidBarInfo from './SideBarInfo'
import PatientHistory from './PatientHistory'
import { Outlet, useLocation, useOutletContext } from 'react-router-dom'
import { useState } from 'react'
import { ISharedDiagnostic } from '../../../types/diagnostics'

type ContextType = {
  setDiagnosticData: (data: ISharedDiagnostic) => void
}

export default function PatientCard() {
  const location = useLocation()
  const isWhiteboardView = location.pathname.includes('/diagnostics/')
  const [diagnosticData, setDiagnosticData] =
    useState<ISharedDiagnostic | null>(null)

  return (
    <Box display={'flex'} h={'100%'} w={'100%'}>
      <Box w={'20%'}>
        <SidBarInfo diagnosticData={isWhiteboardView ? diagnosticData : null} />
      </Box>
      <Box w={'80%'}>
        {isWhiteboardView ? (
          <Outlet context={{ setDiagnosticData } as ContextType} />
        ) : (
          <PatientHistory />
        )}
      </Box>
    </Box>
  )
}

export function useDiagnosticContext() {
  return useOutletContext<ContextType>()
}
