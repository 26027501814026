import React from 'react'

function VideoCamera() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1663 3.83332L13.333 7.99999L19.1663 12.1667V3.83332Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6663 2.16666H2.49967C1.5792 2.16666 0.833008 2.91285 0.833008 3.83332V12.1667C0.833008 13.0871 1.5792 13.8333 2.49967 13.8333H11.6663C12.5868 13.8333 13.333 13.0871 13.333 12.1667V3.83332C13.333 2.91285 12.5868 2.16666 11.6663 2.16666Z"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default VideoCamera
