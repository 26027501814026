import { GridItem, GridItemProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

interface IConfirmButton extends GridItemProps {
  children: ReactNode
}

export default function CustomGridItem({ children, ...props }: IConfirmButton) {
  return (
    <GridItem
      colSpan={1}
      display={'grid'}
      alignItems={'center'}
      overflow={'hidden'}
      textOverflow={'ellipsis'}
      whiteSpace={'nowrap'}
      {...props}
    >
      {children}
    </GridItem>
  )
}
