import {
  StatGroup,
  Stat,
  StatLabel,
  Icon,
  StatNumber,
  Badge,
} from '@chakra-ui/react'
import { FaBacterium, FaTint, FaPercentage } from 'react-icons/fa'
import { PARASITEMIA_THRESHOLD } from '../../../utils/constants'
import { AnalysisMalariaReport } from '../../../models/analysis-malaria-report'

const ReportStats = ({
  analysisMalariaReport,
}: {
  analysisMalariaReport: AnalysisMalariaReport
}) => {
  return (
    <StatGroup flexWrap="wrap" gap={6} mb={6}>
      <Stat
        bg="brand.50"
        p={4}
        borderRadius="xl"
        borderLeft="5px solid"
        borderColor="red.400"
      >
        <StatLabel
          fontWeight="medium"
          color="gray.600"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Icon as={FaBacterium} color="red.500" />
          Total infected RBCs
        </StatLabel>
        <StatNumber fontSize="2xl">
          {analysisMalariaReport.infectedRbcCount}
        </StatNumber>
      </Stat>

      <Stat
        bg="brand.50"
        p={4}
        borderRadius="xl"
        borderLeft="5px solid"
        borderColor="green.400"
      >
        <StatLabel
          fontWeight="medium"
          color="gray.600"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Icon as={FaTint} color="green.500" />
          Uninfected Blood Components
        </StatLabel>
        <StatNumber fontSize="2xl">
          {analysisMalariaReport.uninfectedRbcCount}
        </StatNumber>
      </Stat>

      <Stat
        bg="brand.50"
        p={4}
        borderRadius="xl"
        borderLeft="5px solid"
        borderColor="blue.400"
      >
        <StatLabel
          fontWeight="medium"
          color="gray.600"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Icon as={FaPercentage} color="blue.500" />
          Estimated Parasitaemia
        </StatLabel>
        <StatNumber fontSize="2xl" display="flex" alignItems="center">
          {analysisMalariaReport.estimatedParasitemiaPercent.toFixed(2)}%
          <Badge
            ml={2}
            colorScheme={
              analysisMalariaReport.estimatedParasitemiaPercent >
              PARASITEMIA_THRESHOLD
                ? 'red'
                : 'green'
            }
          >
            {analysisMalariaReport.estimatedParasitemiaPercent >
            PARASITEMIA_THRESHOLD
              ? 'High'
              : 'Low'}
          </Badge>
        </StatNumber>
      </Stat>
    </StatGroup>
  )
}

export default ReportStats
