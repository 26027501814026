import { IImageZone } from '../../../types/image-zone'
import { useGet } from '../../query/useGet'

export function useGetAllImageZones(
  diagnosticId: string,
  diagnosticImageId: string
) {
  const imageZones = useGet<IImageZone[]>(
    `diagnostics/${diagnosticId}/diagnostic-images/${diagnosticImageId}/image-zones`
  )
  const result = imageZones.data

  return { result, ...imageZones }
}
