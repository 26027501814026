import { AnalysisImageProcessEnum } from '../../../types/diagnostic-image'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { Flex, Box, Text } from '@chakra-ui/react'
import ProcessingMsg from '../../_shared/ProcessingMsg'

const ReportStatusMsg = ({
  reportProcess,
}: {
  reportProcess: AnalysisImageProcessEnum
}) => {
  const isReportFailed = reportProcess === AnalysisImageProcessEnum.Failed
  const isReportInProgress =
    reportProcess === AnalysisImageProcessEnum.InProgress
  return (
    <>
      {isReportFailed && (
        <Flex align={'center'} gap={2} mb={4}>
          <InfoOutlineIcon color={'red.400'} />
          <Text
            color={'red.400'}
            fontSize="md"
            fontWeight="medium"
            fontStyle={'italic'}
          >
            At least one error occurred while processing analysis, please try
            again to update the report.
          </Text>
        </Flex>
      )}
      {isReportInProgress && (
        <Box mb={4}>
          <ProcessingMsg />
        </Box>
      )}
    </>
  )
}

export default ReportStatusMsg
