import { Box, Stack, Avatar, Text } from '@chakra-ui/react'
import { UserAround } from '../../../../../api/api_hooks/user/useGetUsersAroundPoint'

interface CorrespondentCardProps {
  user: UserAround
  isSelected?: boolean
  onSelect: (user: UserAround) => void
}

const CorrespondentCard = ({
  user,
  isSelected = false,
  onSelect,
}: CorrespondentCardProps) => {
  const BASE_URL = process.env.REACT_APP_API_DOMAIN

  const handleClick = () => {
    onSelect(user)
  }

  return (
    <Box
      key={user.id}
      w="full"
      p={2}
      onClick={handleClick}
      cursor="pointer"
      bg={isSelected ? 'gray.50' : 'transparent'}
      transition="all 0.2s"
    >
      <Stack direction="row" spacing={4} align="center">
        <Avatar size="md" src={`${BASE_URL}/${user.avatarFilePath}`} />
        <Box>
          <Text fontWeight="bold" color="black">
            {user.contactName}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {user.specialty.name}
          </Text>
        </Box>
      </Stack>
    </Box>
  )
}

export default CorrespondentCard
