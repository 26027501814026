import { Box, Button, Center, Link, useToast } from '@chakra-ui/react'
import FormHead from '../../components/FormHead'
import { useSearchParams } from 'react-router-dom'
import useAuthAPI from '../../../../api/api_hooks/auth/useAuthAPI'
import { useEffect, useState } from 'react'

export default function RegisterConfirmed() {
  const toast = useToast()
  const { useConfirmEmail } = useAuthAPI()
  const confirmEmail = useConfirmEmail()

  const [searchParams] = useSearchParams()

  const [email, setEmail] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [invitationToken, setInvitationToken] = useState<string | null>(null)

  useEffect(() => {
    setEmail(searchParams.get('email'))
    setToken(searchParams.get('token'))
    setInvitationToken(searchParams.get('invitationToken'))
  }, [searchParams])

  const emailAlreadyConfirmed =
    //@ts-ignore
    confirmEmail?.error?.response.data.message === 'Token not found.'

  useEffect(() => {
    if (email && token)
      confirmEmail.mutate({ email, token, invitationToken } as unknown as void)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, token, invitationToken])

  if (confirmEmail.isError) {
    toast({
      title: emailAlreadyConfirmed
        ? 'Mail was confirm'
        : 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <Box h={'90%'} display={'flex'}>
      <Center flexDirection={'column'} alignItems={'stretch'}>
        <FormHead
          title="Your account requires email confirmation"
          subTitle="We have sent an email to your mailbox. Please confirm the account creation"
        />
        <Link href="/login">
          <Button
            w={'100%'}
            color={'white'}
            background="brand.purple"
            mt={'40px'}
          >
            Go Home
          </Button>
        </Link>
      </Center>
    </Box>
  )
}
