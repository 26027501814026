import { useParams, useSearchParams } from 'react-router-dom'
import { useGet } from '../../query/useGet'
import { usePostProtected } from '../../query/useProtectedPost'
import { useMutation, UseQueryOptions } from '@tanstack/react-query'
import axiosClient from '../../axios'
import { IPatient } from '../../../types/patients'
import { PagePaginations } from '../../../components/Pagination/paginations'
import { MetaAndData } from '../types'
import { ISharedDiagnostic } from '../../../types/diagnostics'

export function usePatientsList() {
  const [searchParams] = useSearchParams()
  const page = searchParams.get(PagePaginations.PATIENTS_PAGE)
  const patientsList = useGet(`/patients?page=${page || 1}&limit=8`)
  return patientsList
}

export function useCreatePatient() {
  const patient = usePostProtected(`/patients`)
  return patient
}

export function useGetPatient(id?: string, config?: UseQueryOptions<IPatient>) {
  let { id: paramID } = useParams()
  const selectedId = paramID || id
  return useGet<IPatient>(`/patients/${selectedId}`, config)
}

export function useDeletePatient() {
  const deletePatient = useMutation({
    mutationKey: ['/deletePatients'],
    mutationFn: (id: string) => {
      return axiosClient.delete(`/patients/${id}`)
    },
  })
  return deletePatient
}

export function useEditPatient() {
  const deletePatient = useMutation({
    mutationKey: ['/editPatients'],
    mutationFn: ({ id, data }: any) => {
      return axiosClient.patch(`/patients/${id}`, data)
    },
  })
  return deletePatient
}

export function useLastManagedPatientsList(limit: number) {
  const useLastManagedPatientsList = useGet(
    `/patients/last-managed-patients?limit=${limit}`
  )
  const result = (useLastManagedPatientsList.data ?? []) as IPatient[]
  return { result, ...useLastManagedPatientsList }
}

export function useGetAllSharedDiagnostics() {
  const { data: patientData } = useGetPatient()
  const [searchParams] = useSearchParams()
  const page = searchParams.get(PagePaginations.DIAGNOSTICS_PAGE)

  const allDiagnostics = useGet<MetaAndData<ISharedDiagnostic[]>>(
    `/patients/${patientData?.id}/diagnostics?page=${page || 1}&sortFiled=updatedAt&sortDirection=DESC&limit=5`
  )
  return allDiagnostics
}
