import { Card, CardBody, Divider } from '@chakra-ui/react'
import { AnalysisMalariaReport } from '../../models/analysis-malaria-report'
import Header from './components/Header'
import ReportStatusMsg from './components/ReportStatusMsg'
import ReportStats from './components/ReportStats'
import ReportTable from './components/ReportTable'

interface AnalysisMalariaResultsCardProps {
  analysisMalariaReport: AnalysisMalariaReport
}

export default function AnalysisMalariaResultsCard({
  analysisMalariaReport,
}: AnalysisMalariaResultsCardProps) {
  return (
    <Card
      fontFamily={'Poppins'}
      w={'100%'}
      mt={6}
      px={4}
      py={2}
      maxW="100%"
      borderRadius={18}
    >
      <Header updatedAt={analysisMalariaReport.updatedAt} />

      <CardBody pt={0}>
        <ReportStatusMsg reportProcess={analysisMalariaReport.process} />
        {analysisMalariaReport.hasResults && (
          <ReportStats analysisMalariaReport={analysisMalariaReport} />
        )}
        <Divider my={4} />

        <ReportTable analysisMalariaReport={analysisMalariaReport} />
      </CardBody>
    </Card>
  )
}
