import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import { IAnalysisImage } from '../../../types/diagnostic-image'

interface TopSwiperProps {
  photoData: IAnalysisImage[]
  setThumbsSwiper: (swiper: any) => void
}

const TopSwiper = ({ photoData, setThumbsSwiper }: TopSwiperProps) => {
  return (
    <Swiper
      onSwiper={setThumbsSwiper}
      spaceBetween={10}
      slidesPerView="auto"
      breakpoints={{
        320: { slidesPerView: 3 },
        480: { slidesPerView: 4 },
        768: { slidesPerView: 5 },
        1024: { slidesPerView: 7 },
      }}
      freeMode={true}
      watchSlidesProgress={true}
      modules={[FreeMode, Navigation, Thumbs]}
      navigation
      className="myTopSwiper"
    >
      {photoData?.map((image) => (
        <SwiperSlide key={image.id}>
          <img alt={`img-${image.id}`} src={image.mediaUrl} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default TopSwiper
