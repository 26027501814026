import { useQuery } from '@tanstack/react-query'
import { publicAxios } from '../../axios'
import { AxiosResponse } from 'axios'
import { ISpecialty } from '../../../types/specialties'
import { DropdownGetHookReturn } from '../types'

export function useGetSpecialties(): DropdownGetHookReturn<ISpecialty> {
  const path = '/specialties'

  const getSpecialties = useQuery<AxiosResponse<ISpecialty[]>>({
    staleTime: 50000,
    queryKey: [path],
    retry: 1,
    queryFn: async () => {
      const response = await publicAxios.get(path)
      return response
    },
  })
  const result = (getSpecialties.data?.data ?? []) as ISpecialty[]

  return { result, ...getSpecialties }
}
