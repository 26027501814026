import { Box, HStack, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import MainLogo from '../../components/MainLogo'
import { Outlet } from 'react-router-dom'
import LoginBg from '../../assets/LoginBg.png'

export default function EnterForm() {
  const [isLargerThan800] = useMediaQuery('(min-width: 992px)')

  return (
    <HStack gap={0} h={'100%'}>
      <Box
        w={{ base: '100%', lg: '40%' }}
        p={'20px 60px'}
        h={'100%'}
        overflow={'auto'}
      >
        <MainLogo />
        <Outlet />
      </Box>
      {isLargerThan800 && (
        <Box
          h={'100%'}
          w="60%"
          bgImage={`url(${LoginBg})`}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="cover"
        />
      )}
    </HStack>
  )
}
