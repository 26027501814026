import { Box, Button, ButtonGroup, List, ListItem } from '@chakra-ui/react'
import Arrow from '../../assets/svg/arrow'
import { useEffect, useState } from 'react'
import { usePaginationArray } from '../../utils/createPaginationArray'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetPrams } from '../../utils/getPrams'
import { PagePaginations } from './paginations'

interface IPagination {
  data: any
  pageName?: string
}

export default function Pagination({ data, pageName = 'page' }: IPagination) {
  const location = useLocation()
  const navigate = useNavigate()
  const page = useGetPrams(pageName)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(1)

  const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1)
  const pages = [...Array(totalPages)]

  const paginationArray = usePaginationArray(pages, currentPage)

  const fT = Object.values(PagePaginations).includes(
    pageName as PagePaginations
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(pageName, currentPage.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
    return () => {
      if (pageName) {
        searchParams.delete(pageName)
        if (!fT) {
          navigate(`${location.pathname}?${searchParams.toString()}`, {
            replace: false,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    if (data?.meta.totalPages) setTotalPages(data?.meta.totalPages)
  }, [data?.meta.totalPages])

  if (totalPages < 2) {
    return <></>
  }

  return (
    <Box display={'flex'} justifyContent={'center'} m={4}>
      <ButtonGroup
        isAttached
        variant="solid"
        bg={'white'}
        borderRadius={8}
        border={'1px solid #D0D5DD'}
      >
        <Button
          leftIcon={<Arrow />}
          borderRadius={8}
          bg={'white'}
          borderRight={'1px solid #D0D5DD'}
          onClick={() => setCurrentPage(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          Previous
        </Button>
        <List display={'flex'}>
          {paginationArray.map((page) => (
            <ListItem key={page}>
              <Button
                bg={currentPage === page ? '#F8EAFF' : 'white'}
                color={currentPage === page ? 'brand.purple' : undefined}
                borderRadius={0}
                _hover={{
                  background: '#F8EAFF',
                  color: 'brand.purple',
                }}
                onClick={() => setCurrentPage(page as number)}
              >
                {page}
              </Button>
            </ListItem>
          ))}
        </List>
        <Button
          rightIcon={<Arrow />}
          borderRadius={8}
          bg={'white'}
          sx={{ svg: { transform: 'rotate(180deg)' } }}
          borderLeft={'1px solid #D0D5DD'}
          onClick={() => setCurrentPage(currentPage + 1)}
          isDisabled={currentPage === totalPages}
        >
          Next
        </Button>
      </ButtonGroup>
    </Box>
  )
}
