import { ISharedDiagnostic } from './diagnostics'
import { AnalysisTypeEnum, IAnalysis } from './ia-analysis'

export interface IAnalysisImage<T extends IAnalysis = IAnalysis> {
  id: string
  diagnosticId: string
  coordinates: {
    height: number
    width: number
    positive: Array<any>
    negative: Array<any>
    customPositive: Array<any>
  }
  imageFilePath: string
  errorMessage?: string | null
  mediaUrl: string
  process: AnalysisImageProcessEnum
  createdAt: string
  updatedAt: string
  diagnostic?: ISharedDiagnostic
  analysisId?: string
  analysisType?: AnalysisTypeEnum
  analysis?: T
}

export enum AnalysisImageProcessEnum {
  NotStarted = 'notStarted',
  Failed = 'failed',
  Success = 'success',
  InProgress = 'inProgress',
}

export type IAnalysisImageWithAnalysis<T extends IAnalysis = IAnalysis> =
  IAnalysisImage<T> & {
    analysis: T
  }
