import {
  TableContainer,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  Image,
  Text,
} from '@chakra-ui/react'
import moment from 'moment'
import { AnalysisImageProcessEnum } from '../../../types/diagnostic-image'
import ProcessingMsg from '../../_shared/ProcessingMsg'
import { AnalysisMalariaReport } from '../../../models/analysis-malaria-report'

const ReportTable = ({
  analysisMalariaReport,
}: {
  analysisMalariaReport: AnalysisMalariaReport
}) => {
  return (
    <TableContainer mt={4}>
      <Table>
        <TableCaption>
          {`Analysis of ${analysisMalariaReport.totalAnalyzedCells.toLocaleString()} cells across ${analysisMalariaReport.totalAnalysisImagesCount} image${analysisMalariaReport.totalAnalysisImagesCount > 1 ? 's' : ''}`}
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Created At</Th>
            <Th>Image</Th>
            <Th>Status</Th>
            <Th isNumeric>Infected Count</Th>
            <Th isNumeric>Uninfected Count</Th>
            <Th isNumeric>Estimated Parasitemia (%)</Th>
          </Tr>
        </Thead>
        <Tbody color={'brand.lightGrayText'}>
          {analysisMalariaReport.diagnosticImages.map((imageWithAnalysis) => (
            <Tr key={imageWithAnalysis.id}>
              <Td>
                {moment(imageWithAnalysis.analysis?.createdAt).format(
                  'DD.MM.YY HH:mm'
                )}
              </Td>
              <Td>
                <Image
                  src={imageWithAnalysis.mediaUrl}
                  alt="Image"
                  boxSize="32px"
                  objectFit="cover"
                  borderRadius={8}
                />
              </Td>
              <Td>
                {imageWithAnalysis.process ===
                AnalysisImageProcessEnum.InProgress ? (
                  <ProcessingMsg />
                ) : imageWithAnalysis.process ===
                  AnalysisImageProcessEnum.Failed ? (
                  <Text color="red.400" fontSize="sm" fontWeight="bold">
                    {imageWithAnalysis.process.toUpperCase()}
                  </Text>
                ) : (
                  <Text>{imageWithAnalysis.process.toUpperCase()}</Text>
                )}
              </Td>
              <Td isNumeric>
                <Text>{imageWithAnalysis.analysis?.infectedRbcCount}</Text>
              </Td>
              <Td isNumeric>
                <Text>{imageWithAnalysis.analysis?.uninfectedRbcCount}</Text>
              </Td>
              <Td isNumeric>
                {imageWithAnalysis.analysis?.estimatedParasitemiaPercent
                  ? `${Number(
                      imageWithAnalysis.analysis.estimatedParasitemiaPercent
                    ).toFixed(2)}%`
                  : '-'}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default ReportTable
