import { Box, VStack } from '@chakra-ui/react'
import InProgressRequests from '../../components/InProgressRequests'
import LastManagedPatients from '../../components/LastManagedPatients'
import HomeHeader from '../../components/HomeHeader'

export default function Home() {
  return (
    <Box
      p={{
        base: '32px 16px',
        lg: '32px 100px',
        xl: '32px 200px',
        '2xl': '32px 300px',
      }}
      height="100vh"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <VStack spacing={8} align="stretch" height="100%" overflow="hidden">
        <HomeHeader />
        <Box flex={1} overflow="hidden">
          <Box overflow="hidden" h={'50%'}>
            <InProgressRequests />
          </Box>
          <Box overflow="hidden" h={'50%'}>
            <LastManagedPatients />
          </Box>
        </Box>
      </VStack>
    </Box>
  )
}
