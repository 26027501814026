import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import SideBar from '../../components/SideBar'

export default function DashboardLayout() {
  return (
    <Box display={'flex'} h={'100vh'} overflow="hidden">
      <Box bg="brand.sideBarBlack" height="100%">
        <SideBar />
      </Box>
      <Box w="100%" background={'#F1EFEE'} overflowY="auto" height="100%">
        <Outlet />
      </Box>
    </Box>
  )
}
