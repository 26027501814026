import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { RequestFormData } from '..'
import ControlInput from '../../../Forms/components/ControlInput'
import SearchUsersComponent from '../../../Forms/components/SearchUsersComponent'
import { ChangeEvent, useState } from 'react'
import Invite from '../../../../assets/svg/invite'
import { UserAround } from '../../../../api/api_hooks/user/useGetUsersAroundPoint'

interface DoctorSelectionStepProps {
  setReceiverDoctorDisplayName: (name: string | null) => void
}

export default function DoctorSelectionStep({
  setReceiverDoctorDisplayName,
}: DoctorSelectionStepProps) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<RequestFormData>()

  const [selectedUser, setSelectedUser] = useState<UserAround | null>(null)
  const [selectedUserEmail, setSelectedUserEmail] = useState<string | null>(
    null
  )
  const { isOpen, onToggle, onClose } = useDisclosure()

  const selectUser = (user: UserAround | null) => {
    if (!user) {
      return
    }
    setSelectedUser(user)
    setValue('receiverEmail', user.email)
    setSelectedUserEmail(null)
    setReceiverDoctorDisplayName(user.contactName)
    onClose()
  }

  const selectEmail = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const email = e.target.value
    setSelectedUserEmail(email)
    setValue('receiverEmail', email)
    setReceiverDoctorDisplayName(email)
    setSelectedUser(null)
  }

  return (
    <Box>
      <SearchUsersComponent
        selectedUser={selectedUser}
        setSelectedUser={selectUser}
      />
      <Accordion index={isOpen ? 0 : -1} allowToggle>
        <AccordionItem border={0}>
          <Box py={8}>
            <AccordionButton onClick={onToggle}>
              <Box display="flex" alignItems="center" gap={4}>
                <Invite />
                <Box textAlign={'left'}>
                  <Text fontWeight={'bold'}>Or invite Colleagues</Text>
                  <Text
                    fontSize={14}
                    fontWeight={400}
                    color={'brand.lightGrayText'}
                  >
                    Enter an email address to invite a colleague
                  </Text>
                </Box>
              </Box>
            </AccordionButton>
            <AccordionPanel>
              <ControlInput
                label="Email"
                placeholder="Enter an email address"
                onChange={selectEmail}
                value={selectedUserEmail ?? ''}
              />
            </AccordionPanel>
          </Box>
        </AccordionItem>
      </Accordion>
      {errors.receiverEmail && (
        <Text color="red.500">{errors.receiverEmail?.message}</Text>
      )}
    </Box>
  )
}
