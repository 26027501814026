import ControlInput from '../components/ControlInput'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { MyProfile } from '../../../utils/schema'
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react'
import AvatarBox from '../../AvatarBox'
import { useUpdateProfile } from '../../../api/api_hooks/user/useUpdateProfile'
import { useEffect } from 'react'
import { useMyProfile } from '../../../api/api_hooks/user/myProfile'
import AddressInput from '../components/AddressInput'
import TooltipLabel from '../components/TooltipLabel'
import { useGetSpecialties } from '../../../api/api_hooks/specialties'
import GenericDropdown from '../../_shared/GenericDropdown'

type Inputs = {
  companyName: string
  contactName: string
  phoneNumber: string
  email: string
  specialtyId: number
  avatar?: any
  fullAddress?: string
  location?: {
    lat: string
    lon: string
  }
  adeliFinessNumber?: string
  rppsNumber?: string
}

export default function ProfileForm() {
  const toast = useToast()
  const { data: userData, refetch, isError } = useMyProfile()
  const { mutate, isSuccess, isError: updatePrifile } = useUpdateProfile()
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(MyProfile),
  })
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const file = data.avatar[0]
    const formData = new FormData()
    formData.append('companyName', data.companyName)
    formData.append('contactName', data.contactName)
    formData.append('email', data.email)
    formData.append('phoneNumber', data.phoneNumber)
    formData.append('specialtyId', data.specialtyId.toString())
    formData.append('file', file)

    const optionalFields: (keyof Pick<
      Inputs,
      'fullAddress' | 'adeliFinessNumber' | 'rppsNumber'
    >)[] = ['fullAddress', 'adeliFinessNumber', 'rppsNumber']
    optionalFields.forEach((field) => {
      if (data[field]) {
        formData.append(field, data[field] as string)
      }
    })
    if (data.location) {
      formData.append('latitude', data.location.lat)
      formData.append('longitude', data.location.lon)
    }

    mutate(formData as unknown as void)
  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: `Data update - success`,
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (userData) {
      setValue('companyName', userData.companyName)
      setValue('contactName', userData.contactName)
      setValue('email', userData.email)
      setValue('phoneNumber', userData.phoneNumber)
      setValue('specialtyId', userData.specialtyId)
      setValue('fullAddress', userData.fullAddress)
      if (userData.location?.coordinates) {
        setValue('location', {
          lon: userData.location.coordinates[0].toString(),
          lat: userData.location.coordinates[1].toString(),
        })
      }
      setValue('adeliFinessNumber', userData.adeliFinessNumber)
      setValue('rppsNumber', userData.rppsNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  if (isError || updatePrifile) {
    toast({
      title: 'Something went wrong try again',
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }

  const watchAvatar = watch('avatar')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex mt={20} flexDirection={'column'} alignItems="flex-start">
        <Flex justifyContent={'center'} alignItems={'center'}>
          <AvatarBox
            avatar={watchAvatar?.[0] || userData?.avatarFilePath}
            register={{ ...register('avatar') }}
            isInvalid={!!errors.avatar}
            errorMessage={errors.avatar && errors.avatar.message}
          />
          <Box w={'100%'} pl={4}>
            <Text fontWeight={600} fontSize={'xl'} pb={2}>
              {userData?.contactName}
            </Text>
            <Text fontSize={'md'}>{userData?.specialty?.name}</Text>
          </Box>
        </Flex>
        <Flex w={'100%'} mt={5} p={4} gap={4}>
          <Box w={'100%'}>
            <ControlInput
              register={{ ...register('contactName') }}
              label="Contact Name"
              placeholder="Contact Name"
              isInvalid={!!errors.contactName}
              errorMessage={errors.contactName && errors.contactName.message}
            />
            <ControlInput
              register={{ ...register('phoneNumber') }}
              label="Phone Number"
              placeholder="Phone Number"
              isInvalid={!!errors.phoneNumber}
              errorMessage={errors.phoneNumber && errors.phoneNumber.message}
            />
            <ControlInput
              register={{ ...register('companyName') }}
              label="Your company name"
              placeholder="Company Name"
              isInvalid={!!errors.companyName}
              errorMessage={errors.companyName && errors.companyName.message}
            />
            <AddressInput
              onLocationSelect={(loc, address) => {
                setValue('location', {
                  lat: loc.lat,
                  lon: loc.lon,
                })
                setValue('fullAddress', address)
              }}
              fullAddressOnly={true}
              label={
                <TooltipLabel
                  labelTitle="Professional Address"
                  labelMessage="Please enter a valid address. Without this information, you will not be visible on the expert map."
                />
              }
              inputData={userData?.fullAddress}
            />
          </Box>
          <Box w={'100%'}>
            <ControlInput
              register={{ ...register('email') }}
              label="Email"
              placeholder="Email"
              isInvalid={!!errors.email}
              errorMessage={errors.email && errors.email.message}
              disabled
            />
            <GenericDropdown
              value={watch('specialtyId')}
              onChange={(value) => setValue('specialtyId', value.id)}
              errorMessage={errors.specialtyId?.message}
              useGetHook={useGetSpecialties}
              label="Specialty"
              placeholder="Search for a specialty"
            />
            <ControlInput
              register={{ ...register('adeliFinessNumber') }}
              label="Adeli/FINESS Number"
              placeholder="XXXXXXXXX"
              isInvalid={!!errors.adeliFinessNumber}
              errorMessage={
                errors.adeliFinessNumber && errors.adeliFinessNumber.message
              }
            />
            <ControlInput
              register={{ ...register('rppsNumber') }}
              label="RPPS Number"
              placeholder="XXXXXXXXXXX"
              isInvalid={!!errors.rppsNumber}
              errorMessage={errors.rppsNumber && errors.rppsNumber.message}
            />
          </Box>
        </Flex>
        <Flex gap={4} mt={5} justifyContent="flex-end" width="100%">
          <Button
            onClick={() => reset()}
            variant="outline"
            p={'0px 40px'}
            border={'1px solid'}
            borderColor={'brand.lightGray'}
            color="brand.lightGrayText"
          >
            Reset
          </Button>
          <Button colorScheme="purple" type="submit" p={'0px 40px'}>
            Update
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}
