import { Flex, Button, Text } from '@chakra-ui/react'
import moment from 'moment'
import VideoCamera from '../../../assets/svg/videoCamera'
import { ISharedDiagnostic } from '../../../types/diagnostics'

const Header = ({
  sharedDiagnostic,
}: {
  sharedDiagnostic: ISharedDiagnostic
}) => {
  return (
    <Flex w={'100%'} p={1} flexDirection={'column'} fontFamily={'Poppins'}>
      <Flex w={'100%'} justifyContent={'space-between'}>
        <Text fontWeight={'bold'} fontSize={'xx-large'}>
          {sharedDiagnostic.title.charAt(0).toUpperCase() +
            sharedDiagnostic.title.slice(1)}
        </Text>
        <Button leftIcon={<VideoCamera />} colorScheme="purple">
          Run Live
        </Button>
      </Flex>
      <Text fontSize={'lg'}>
        Added {moment(sharedDiagnostic.createdAt).format('DD/MM/YYYY')} at{' '}
        {moment(sharedDiagnostic.createdAt).format('HH:mm')},
        <Text as={'span'} color="brand.darkGray" fontStyle={'italic'}>
          {' '}
          updated {moment(sharedDiagnostic.updatedAt).fromNow()}
        </Text>
      </Text>
    </Flex>
  )
}

export default Header
