import {
  Avatar,
  Text,
  VStack,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import moment from 'moment'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useMyProfile } from '../../../api/api_hooks/user/myProfile'
import { IComment } from '../../../types/comments'
import { useMemo } from 'react'

const CommentSection = ({ comment }: { comment: IComment }) => {
  const { data: currentUser } = useMyProfile()

  const isOwner = useMemo(() => {
    return currentUser?.id === comment.doctorId
  }, [currentUser, comment.doctorId])

  return (
    <>
      <HStack
        w={'100%'}
        justifyContent="space-between"
        fontFamily={'Poppins'}
        gap={4}
      >
        <HStack spacing={4}>
          <Avatar
            size="sm"
            name={comment.owner?.contactName}
            border={'1px solid white'}
            src={comment.owner?.avatarUrl}
          />
          <VStack align="start" spacing={0}>
            <Text fontWeight="bold" fontSize="sm">
              Dr. {comment.owner?.contactName}
            </Text>
            <Text fontSize="xs" opacity={0.7}>
              {moment(comment.createdAt).format('HH:mm DD/MM/YYYY')}
            </Text>
          </VStack>
        </HStack>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<BsThreeDotsVertical />}
            colorScheme="white"
            size="sm"
          />
          <MenuList color={'brand.darkGrayBg'}>
            <MenuItem backgroundColor={'white'} isDisabled={!isOwner}>
              Edit
            </MenuItem>
            <MenuItem backgroundColor={'white'} isDisabled={!isOwner}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      <Text my={2}>{comment.commentText}</Text>
    </>
  )
}

export default CommentSection
