import { Box, Image, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import CommentComponent from '../../../CommentComponent'
import { IImageZone } from '../../../../types/image-zone'
import ImageZone from './imageZone'
// import styles
import 'react-image-crop/dist/ReactCrop.css'
import './cropStyle.css'
import {
  AnalysisImageProcessEnum,
  IAnalysisImage,
} from '../../../../types/diagnostic-image'
import { useGetAllImageZones } from '../../../../api/api_hooks/image-zones'

interface IDrawImage {
  image: IAnalysisImage
  zoomIn: boolean
  cropToggle: boolean
  setCropToggle: () => void
  showAnalysisImagesZone?: boolean
}

const ZOOM_RATIO: number = 3

function DrawImage({
  image,
  zoomIn,
  cropToggle,
  setCropToggle,
  showAnalysisImagesZone,
}: IDrawImage) {
  const { result: allImageZones, refetch: updateImage } = useGetAllImageZones(
    image.diagnosticId,
    image.id
  )
  const [initialProcess, setInitialProcess] = useState<string>()

  useEffect(() => {
    setInitialProcess(image.process)
  }, [image])

  useEffect(() => {
    if (
      !initialProcess ||
      initialProcess === AnalysisImageProcessEnum.Success
    ) {
      return
    }
    if (image.process !== AnalysisImageProcessEnum.Success) {
      return
    }
    updateImage()
  }, [image.process, initialProcess, updateImage])

  const displayedImageZones = allImageZones?.filter(
    (zone: IImageZone) => showAnalysisImagesZone || zone.isAddedBySelf
  )

  const containerRef = useRef<HTMLDivElement>(null)

  const [renderedImageWidth, setRenderedImageSize] = useState(1)
  const [crop, setCrop] = useState<Crop>()
  const [cropPx, setCropPx] = useState<Crop>()
  const [open, setOpen] = useState<boolean>(false)

  const top = crop && crop?.y + crop.height
  const left = crop && crop?.x + crop.width
  const scaleFactor = renderedImageWidth / image.coordinates.width

  const updateSize = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth
      setRenderedImageSize(containerWidth)
    }
  }

  useLayoutEffect(() => {
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      updateSize()
    }, 300)
  }, [zoomIn])

  const resetCrop = () => {
    setCrop({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      unit: '%',
    })
  }

  const calculateDragZoneCoordinates = () => {
    if (!cropPx || !image) {
      return
    }
    const croppedImgData = zoomIn
      ? [
          Math.floor(cropPx?.x / scaleFactor / ZOOM_RATIO),
          Math.floor(cropPx?.y / scaleFactor / ZOOM_RATIO),
          Math.floor((cropPx?.x + cropPx?.width) / scaleFactor / ZOOM_RATIO),
          Math.floor((cropPx?.y + cropPx?.height) / scaleFactor / ZOOM_RATIO),
        ]
      : [
          Math.floor(cropPx?.x / scaleFactor),
          Math.floor(cropPx?.y / scaleFactor),
          Math.floor((cropPx?.x + cropPx?.width) / scaleFactor),
          Math.floor((cropPx?.y + cropPx?.height) / scaleFactor),
        ]
    return croppedImgData
  }

  useEffect(() => {
    resetCrop()
  }, [cropToggle])

  return (
    <div ref={containerRef}>
      <Box
        transitionDuration={'300ms'}
        transform={`translate3d(0px, 0px, 0px) scale(${zoomIn ? ZOOM_RATIO : 1})`}
        position={'relative'}
        display={'inline-block'}
      >
        {open && crop && crop?.width > 1 && (
          <>
            <Tooltip
              placement="bottom"
              label={
                <CommentComponent
                  diagnosticImageId={image.id}
                  newZoneCoordinates={calculateDragZoneCoordinates()}
                  onCommentCreated={async () => {
                    resetCrop()
                    await updateImage()
                    setCropToggle()
                  }}
                />
              }
              isOpen
              pointerEvents={'all'}
              hasArrow
              arrowSize={14}
              backgroundColor={'white'}
              borderRadius={'md'}
            >
              <Box
                position={'absolute'}
                top={`${top}%`}
                left={`${left}%`}
                zIndex={9}
              ></Box>
            </Tooltip>
          </>
        )}
        <ReactCrop
          disabled={cropToggle}
          crop={crop}
          onChange={(cropPx, percentCrop) => {
            setCrop(percentCrop)
            setCropPx(cropPx)
          }}
          onDragEnd={() => {
            setOpen(true)
          }}
          locked={cropToggle}
        >
          <Box>
            <Image
              cursor={zoomIn && cropToggle ? 'grab' : ''}
              transform={'none !important'}
              minW={image.coordinates.width * scaleFactor}
              minH={image.coordinates.height * scaleFactor}
              id="dynamicImage"
              alt=""
              src={image.mediaUrl}
            />
          </Box>
          {displayedImageZones?.map((imageZone: IImageZone, i: number) => {
            return (
              <ImageZone
                key={imageZone.id}
                imageZone={imageZone}
                scaleFactor={scaleFactor}
                onCommentCreated={async () => {
                  await updateImage()
                }}
              />
            )
          })}
        </ReactCrop>
      </Box>
    </div>
  )
}

export const MemoizedDrawImage = React.memo(DrawImage)
