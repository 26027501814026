import { AnalysisMalariaReport } from '../../../models/analysis-malaria-report'
import {
  IAnalysisImage,
  IAnalysisImageWithAnalysis,
} from '../../../types/diagnostic-image'
import { AnalysisTypeEnum, IAnalysisMalaria } from '../../../types/ia-analysis'
import AnalysisMalariaResultsCard from '../../AnalysisMalariaResultsCard'

interface AnalysisComponentFactoryProps {
  diagnosticImages: IAnalysisImage[]
}
const AnalysisComponentFactory = ({
  diagnosticImages,
}: AnalysisComponentFactoryProps) => {
  const analysisTypeName = diagnosticImages[0].analysisType
  if (!analysisTypeName) {
    return <div>Unknown analysis type</div>
  }

  let component
  switch (analysisTypeName) {
    case AnalysisTypeEnum.MALARIA:
      component = (
        <AnalysisMalariaResultsCard
          analysisMalariaReport={
            new AnalysisMalariaReport(
              diagnosticImages as IAnalysisImageWithAnalysis<IAnalysisMalaria>[]
            )
          }
        />
      )
      break
    default:
      component = <div>Unknown analysis type</div>
      break
  }

  return component
}

export default AnalysisComponentFactory
