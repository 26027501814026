import axiosClient from '../../axios'
import { useQuery } from '@tanstack/react-query'
import { getSavedAuthData } from '../../axios/interceptor'
import { IUser } from '../../../types/users'

export function useMyProfile() {
  const { userId } = getSavedAuthData()
  return useQuery({
    staleTime: 50000,
    queryKey: [userId],
    queryFn: async () => {
      const { data } = await axiosClient.get<IUser>('/users/profile')
      return data
    },
  })
}
