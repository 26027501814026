import { Box, Center, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import EmptyFile from '../../../../assets/svg/emptyFile'
import Pagination from '../../../Pagination'
import { PagePaginations } from '../../../Pagination/paginations'
import { useGetAllSharedDiagnostics } from '../../../../api/api_hooks/patients'
import SharedCase from '../../../SharedCaseCard'
import NewRequestModal from '../../NewRequestModal'

export default function PatientHistory() {
  const { data, refetch } = useGetAllSharedDiagnostics()

  return (
    <Box
      h={'100%'}
      w={'100%'}
      overflow={'hidden'}
      p={{
        base: '32px 16px',
        lg: '32px 100px',
        xl: '32px 200px',
        '2xl': '32px 300px',
      }}
    >
      <Flex justifyContent={'space-between'}>
        <Box>
          <Heading as="h2" size="xl" fontFamily={'Poppins'}>
            Patient history
          </Heading>
          <Heading as="h4" size="md" mb={8} mt={4} fontFamily={'Poppins'}>
            Last results
          </Heading>
        </Box>
        <Box>
          <NewRequestModal onRequestCreated={refetch} />
        </Box>
      </Flex>

      <VStack mt={5} gap={5}>
        {data?.data?.length ? (
          <>
            {data?.data?.map((sharedDiagnostic) => (
              <SharedCase key={sharedDiagnostic.id} data={sharedDiagnostic} />
            ))}
          </>
        ) : (
          <Center gap={3} mt={'150px'}>
            <EmptyFile />
            <Text fontSize="2xl" color={'brand.lightGrayText'}>
              No results available for this patient yet
            </Text>
          </Center>
        )}
        <Pagination data={data} pageName={PagePaginations.DIAGNOSTICS_PAGE} />
      </VStack>
    </Box>
  )
}
