import { InfoIcon } from '@chakra-ui/icons'
import { Flex, Text, Tooltip } from '@chakra-ui/react'

export default function TooltipLabel({
  labelTitle,
  labelMessage,
}: {
  labelTitle: string
  labelMessage: string
}) {
  return (
    <Flex alignItems="center" gap={2}>
      <Text color={'brand.darkGray'}>{labelTitle}</Text>
      <Tooltip label={labelMessage} fontSize="sm" placement="right">
        <InfoIcon boxSize={4} color="gray.500" cursor="help" />
      </Tooltip>
    </Flex>
  )
}
