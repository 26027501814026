import { useEffect, useState } from 'react'
import {
  FormControlProps,
  Flex,
  TagCloseButton,
  FormControl,
  FormErrorMessage,
  Tag,
} from '@chakra-ui/react'
import { ITag } from '../../../../types/tags'
import { useGetTags } from '../../../../api/api_hooks/tags'
import GenericDropdown from '../../../_shared/GenericDropdown'

interface TagsDropdownProps<T extends ITag>
  extends Omit<FormControlProps, 'onChange'> {
  values: T['id'][]
  onChange: (value: T['id'][]) => void
  isInvalid?: boolean
  errorMessage?: string
}

function TagsDropdown<T extends ITag>({
  values,
  onChange,
  isInvalid,
  errorMessage,
  ...props
}: TagsDropdownProps<T>): JSX.Element {
  const [allTags, setAllTags] = useState<ITag[]>([])
  const [selectedTags, setSelectedTags] = useState<ITag[]>([])

  useEffect(() => {
    if (!values) {
      return
    }
    const selectedTags = allTags.filter((tag) => values.includes(tag.id))
    setSelectedTags(selectedTags)
  }, [values, allTags])

  const onSelectTag = (tag: ITag) => {
    const newTags = Array.from(new Set([...selectedTags, tag]))
    setSelectedTags(newTags)
    onChange(newTags.map((tag) => tag.id))
  }

  const onDeleteTag = (tag: ITag) => {
    const newTags = selectedTags.filter((t) => t.id !== tag.id)
    setSelectedTags(newTags)
    onChange(newTags.map((tag) => tag.id))
  }

  return (
    <FormControl {...props} isInvalid={isInvalid} color={'brand.lightGray'}>
      <Flex direction="column" w="100%">
        <GenericDropdown
          value=""
          onChange={onSelectTag}
          useGetHook={useGetTags}
          label="Tags"
          placeholder="Find a tag (eg. urgent)"
          clearValueOnSelect={true}
          isInvalid={isInvalid}
          setElements={setAllTags}
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
        <Flex py={2} gap={1} wrap="wrap">
          {selectedTags?.map((tag) => (
            <Tag
              key={tag.id}
              bgColor={`${tag.color}30`}
              fontFamily={'Poppins'}
              letterSpacing={'0.6px'}
              fontSize={'small'}
            >
              #{tag.name}
              <TagCloseButton onClick={() => onDeleteTag(tag)} />
            </Tag>
          ))}
        </Flex>
      </Flex>
    </FormControl>
  )
}

export default TagsDropdown
