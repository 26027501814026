import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

type SharedDiagnosticInfoItemProps = {
  label: string
  children: React.ReactNode
}

const SharedDiagnosticInfoItem = ({
  label,
  children,
}: SharedDiagnosticInfoItemProps) => {
  return (
    <Flex alignItems={'start'}>
      <Box w={'40%'}>
        <Text fontSize="sm" color={'brand.lightGrayText'} mr={2}>
          {label}
        </Text>
      </Box>
      <Box w={'60%'}>{children}</Box>
    </Flex>
  )
}

export default SharedDiagnosticInfoItem
