import { useMutation } from '@tanstack/react-query'
import { ISharedDiagnostic } from '../../../types/diagnostics'
import axiosClient from '../../axios'

export function useRespondToInvitationSharedDiagnostic(diagnosticId: string) {
  const acceptSharedDiagnostic = useMutation({
    mutationKey: ['acceptSharedDiagnostic'],
    mutationFn: (invitationRespond: ISharedDiagnostic['receiverStatus']) => {
      return axiosClient.post<ISharedDiagnostic>(
        `/diagnostics/${diagnosticId}/invitation-respond`,
        { invitationRespond }
      )
    },
  })
  return acceptSharedDiagnostic
}
