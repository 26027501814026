import { VStack, Text, Box } from '@chakra-ui/react'
import CorrespondentCard from './CorrespondentCard'
import { UserAround } from '../../../../../api/api_hooks/user/useGetUsersAroundPoint'

interface SuggestedCorrespondentsProps {
  users: UserAround[]
  selectedUser: UserAround | null
  onSelectUser: (user: UserAround) => void
}

const SuggestedCorrespondents = ({
  users,
  selectedUser,
  onSelectUser,
}: SuggestedCorrespondentsProps) => {
  return (
    <Box mt={4} w="full" h="full" pt={4}>
      <Text
        fontSize="md"
        fontWeight="extrabold"
        color="black"
        textAlign="left"
        w="full"
        mb={4}
      >
        Suggested correspondents
      </Text>
      <VStack spacing={4} w="full" align="stretch">
        {users?.map((user) => (
          <CorrespondentCard
            user={user}
            isSelected={selectedUser?.id === user.id}
            onSelect={(selectedUser) => {
              onSelectUser(user)
            }}
          />
        ))}
        {users?.length === 0 && (
          <Text color="gray.500">No correspondents found</Text>
        )}
      </VStack>
    </Box>
  )
}

export default SuggestedCorrespondents
