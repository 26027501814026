import { useState } from 'react'
import SharedCase from '../../SharedCaseCard'
import {
  Box,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import {
  useGetReceivedSharedDiagnostics,
  useGetSentSharedDiagnostics,
} from '../../../api/api_hooks/shared-diagnostic'
import Pagination from '../../Pagination'
import { useGetPrams } from '../../../utils/getPrams'
import { PagePaginations } from '../../Pagination/paginations'

export default function MyCollaboratory() {
  const tabIndex: number = Number(useGetPrams('tabIndex'))
  const [activeTabIndex, setActiveTabIndex] = useState(
    !isNaN(tabIndex) ? tabIndex : 0
  )

  const { data: sentSharedDiagnostics } = useGetSentSharedDiagnostics()
  const {
    data: receivedSharedDiagnostics,
    refetch: reloadReceivedSharedDiagnostics,
  } = useGetReceivedSharedDiagnostics()

  return (
    <Tabs
      index={activeTabIndex}
      onChange={(index) => setActiveTabIndex(index)}
      colorScheme="purple"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <TabList>
        <Tab>Requesting</Tab>
        <Tab>Required</Tab>
      </TabList>

      <TabPanels flex="1" overflow="hidden">
        <TabPanel height="100%" display="flex" flexDirection="column">
          <Box flex="1" overflow="auto">
            <VStack align="stretch" gap={5}>
              {sentSharedDiagnostics?.data?.map((sharedCase) => (
                <SharedCase key={sharedCase.id} data={sharedCase} />
              ))}
            </VStack>
          </Box>
          <Box mt={4}>
            <Pagination
              data={sentSharedDiagnostics}
              pageName={PagePaginations.SENT_CASE_PAGE}
            />
          </Box>
        </TabPanel>

        <TabPanel height="100%" display="flex" flexDirection="column">
          <Box flex="1" overflow="auto">
            <VStack align="stretch" gap={5}>
              {receivedSharedDiagnostics?.data?.map((sharedCase) => (
                <SharedCase
                  key={sharedCase.id}
                  data={sharedCase}
                  reloadReceivedSharedDiagnostics={
                    reloadReceivedSharedDiagnostics
                  }
                />
              ))}
            </VStack>
          </Box>
          <Box mt={4}>
            <Pagination
              data={receivedSharedDiagnostics}
              pageName={PagePaginations.RECEIVED_CASE_PAGE}
            />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
