import { FormControl, FormErrorMessage, Select, Text } from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'

interface IControlInput {
  label?: string
  isInvalid?: boolean
  placeholder?: string
  errorMessage?: string
  register?: UseFormRegisterReturn
  options?: string[]
}

export default function ControlSelect({
  label,
  isInvalid,
  placeholder,
  errorMessage,
  register,
  options,
  ...props
}: IControlInput) {
  return (
    <FormControl {...props} isInvalid={isInvalid} mt={4}>
      <Text>{label}</Text>
      <Select
        {...register}
        placeholder={placeholder}
        borderRadius={8}
        _placeholder={{ color: 'brand.lightGray' }}
        background={'white'}
        mt={1}
      >
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
