import { Box, Flex, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import WhiteBoardCard from './components/WhiteBoardCard'
import { useGetSharedDiagnosticById } from '../../api/api_hooks/shared-diagnostic'
import { useCallback, useEffect, useRef } from 'react'
import { useDiagnosticContext } from '../PatientsBox/PatientProfile'
import Header from './components/Header'
import FinalizeModal from './components/FinalizeModal'
import { AnalysisImageProcessEnum } from '../../types/diagnostic-image'
import Analyses from '../Analyses'
import { useParams } from 'react-router-dom'
import { useGetSharedDiagnosticImages } from '../../api/api_hooks/shared-diagnostic-images'

export default function Whiteboard() {
  const { setDiagnosticData } = useDiagnosticContext()
  const {
    data: sharedDiagnosticData,
    isLoading,
    error,
    refetch: refetchSharedDiagnostic,
  } = useGetSharedDiagnosticById()
  const diagnosticId = useParams().diagnosticId
  const { data: diagnosticImages, refetch: refetchDiagnosticImages } =
    useGetSharedDiagnosticImages(diagnosticId!)

  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const latestImages = useRef(diagnosticImages)

  useEffect(() => {
    latestImages.current = diagnosticImages
  }, [diagnosticImages])

  // Transfer the sharedDiagnosticData to the context to display tags in the sidebar
  useEffect(() => {
    if (sharedDiagnosticData) {
      setDiagnosticData(sharedDiagnosticData)
    }
  }, [sharedDiagnosticData, setDiagnosticData])

  const refetchImagesIfNecessary = useCallback(() => {
    if (timeoutId.current != null) {
      clearTimeout(timeoutId.current)
      timeoutId.current = null
    }

    // Use latestImages.current to use the latest value
    const imagesInProgress = (latestImages.current ?? []).some(
      (image) => image.process === AnalysisImageProcessEnum.InProgress
    )

    if (!imagesInProgress) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
      return
    }

    const timeoutIdTemp = setTimeout(async () => {
      await refetchDiagnosticImages()
      refetchImagesIfNecessary()
    }, 30000)
    timeoutId.current = timeoutIdTemp
  }, [refetchDiagnosticImages])

  useEffect(() => {
    refetchImagesIfNecessary()
  }, [diagnosticImages, refetchImagesIfNecessary, timeoutId])

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
    }
  }, [])

  if (isLoading) {
    return (
      <Flex justify="center" align="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  if (error || !sharedDiagnosticData) {
    return (
      <Flex justify="center" align="center">
        <Text color="red.500">An error occurred while loading the data</Text>
      </Flex>
    )
  }

  return (
    <Box
      h={'100%'}
      overflowY={'auto'}
      overflowX={'hidden'}
      p={{
        base: '32px 16px',
        lg: '32px 100px',
        xl: '32px 120px',
      }}
    >
      <Header sharedDiagnostic={sharedDiagnosticData} />
      <WhiteBoardCard
        sharedDiagnostic={sharedDiagnosticData}
        onOpen={onOpen}
        diagnosticImages={diagnosticImages ?? []}
        refetchDiagnosticImages={refetchDiagnosticImages}
      />
      <Analyses diagnosticImages={diagnosticImages ?? []} />
      <FinalizeModal
        isOpen={isOpen}
        onClose={onClose}
        refetchSharedDiagnostic={refetchSharedDiagnostic}
      />
    </Box>
  )
}
