import {
  VStack,
  IconButton,
  useToast,
  Icon,
  Text,
  Flex,
  Divider,
  Box,
  Textarea,
  Image,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import {
  useGetZoneComments,
  useAddCommentToZone,
  useCreateImageZone,
} from '../../api/api_hooks/comments'
import { IComment } from '../../types/comments'
import { SmallCloseIcon } from '@chakra-ui/icons'
import CommentSection from './components/CommentSection'
import { useGetSharedDiagnosticById } from '../../api/api_hooks/shared-diagnostic'
import ConfirmButton from '../Forms/components/ConfirmButton'
import TextareaAutosize from 'react-textarea-autosize'
import { IoIosSend } from 'react-icons/io'

interface ICommentComponentProps {
  onClose?: () => void
  onCommentCreated?: () => void
  imageZoneId?: string
  diagnosticImageId: string
  newZoneCoordinates?: number[]
  imageZoneUrl?: string
}

const CommentComponent = ({
  onClose,
  onCommentCreated,
  imageZoneId,
  diagnosticImageId,
  newZoneCoordinates,
  imageZoneUrl,
}: ICommentComponentProps) => {
  const toast = useToast()
  const { result: zoneComments, refetch: updateComments } = useGetZoneComments<
    IComment[]
  >(imageZoneId ?? '')
  const { mutateAsync: addCommentToZone } = useAddCommentToZone()
  const { mutateAsync: createImageZoneHook } = useCreateImageZone()
  const readOnly = useGetSharedDiagnosticById().data?.status !== 'in_process'

  const [newComment, setNewComment] = useState('')
  const isNewZone = !imageZoneId

  const commentsListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Scroll to the bottom of the comments list
    commentsListRef.current?.scrollTo({
      top: commentsListRef.current?.scrollHeight,
      behavior: 'smooth',
    })
  }, [zoneComments])

  const addCommentToExistingZone = () => {
    if (!newComment) {
      return
    }
    addCommentToZone({ imageZoneId, comment: newComment })
      .then(() => {
        setNewComment('')
        updateComments && updateComments()
      })
      .catch(() => {
        toast({
          title: 'Something went wrong try again',
          position: 'top',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => {
        onCommentCreated && onCommentCreated()
      })
  }

  const createImageZone = () => {
    if (!newZoneCoordinates) {
      return
    }
    const data = {
      diagnosticImageId: diagnosticImageId,
      coordinates: newZoneCoordinates,
      commentText: !!newComment ? newComment : undefined,
    }
    createImageZoneHook(data)
      .catch((err) => {
        toast({
          title: 'Comment adding - error',
          position: 'top',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => {
        onCommentCreated && onCommentCreated()
      })
  }

  const createImageZoneOrAddComment = () => {
    if (isNewZone) {
      return createImageZone()
    }

    if (!newComment) {
      return
    }
    addCommentToExistingZone()
  }

  function placeholder() {
    if (isNewZone) {
      return 'Optional comment...'
    }
    if (zoneComments && zoneComments.length > 0) {
      return 'Reply...'
    }
    return 'Add a comment...'
  }

  return (
    <VStack
      align="start"
      w="full"
      maxW="350px"
      fontFamily={'Poppins'}
      py={1}
      maxH={'70vh'}
      gap={1}
    >
      <Flex w="full" justify="space-between" align="center">
        <Text
          fontSize="md"
          fontWeight="bold"
          color={isNewZone ? 'brand.darkGray' : undefined}
        >
          {isNewZone ? 'New collection entry' : 'Discussion'}
        </Text>
        {!isNewZone && (
          <IconButton
            aria-label="Close comments"
            onClick={onClose}
            icon={<SmallCloseIcon />}
            colorScheme="white"
            minW={0}
          />
        )}
      </Flex>
      <Box
        width={'100%'}
        overflowY="auto"
        ref={commentsListRef}
        sx={{
          '::-webkit-scrollbar': { display: 'none' },
          scrollbarWidth: 'none',
        }}
      >
        {imageZoneUrl && (
          <Image
            src={imageZoneUrl}
            borderRadius="sm"
            boxSize="100%"
            alignSelf={'center'}
            alt="Image zone"
            loading="lazy"
            mb={2}
          />
        )}
        {zoneComments?.map((comment) => (
          <React.Fragment key={comment.id}>
            <CommentSection key={comment.id} comment={comment} />
            {zoneComments.indexOf(comment) !== zoneComments.length - 1 && (
              <Divider my={2} opacity={0.2} />
            )}
          </React.Fragment>
        ))}
      </Box>
      {!readOnly && (
        <>
          <Flex w="full" justify="space-between" align="end">
            <Textarea
              as={TextareaAutosize}
              onChange={(e) => setNewComment(e.target.value)}
              value={newComment}
              placeholder={placeholder()}
              minRows={1}
              minH="unset"
              bg="white"
              color="brand.darkGrayBg"
              border={0}
              _focusVisible={{ border: 0 }}
            />
            {!isNewZone && (
              <Icon
                as={IoIosSend}
                onClick={createImageZoneOrAddComment}
                m={2}
                boxSize={5}
                color="white"
                cursor="pointer"
              />
            )}
          </Flex>
          {isNewZone && (
            <ConfirmButton
              text="Add to collection"
              onClick={createImageZoneOrAddComment}
              my={1}
            />
          )}
        </>
      )}
    </VStack>
  )
}

export default CommentComponent
