import { useState } from 'react'
import AddressInput from '../AddressInput'
import { Grid, GridItem, Spinner, Text, VStack } from '@chakra-ui/react'
import { SEARCH_RADIUS_IN_METERS } from '../../../../utils/constants'
import SuggestedCorrespondents from './components/SuggestedCorrespondents'
import {
  useGetUsersAroundPoint,
  UserAround,
} from '../../../../api/api_hooks/user/useGetUsersAroundPoint'
import MapView from './components/MapView'

interface SearchUsersComponentProps {
  selectedUser: UserAround | null
  setSelectedUser: (user: UserAround | null) => void
}

const SearchUsersComponent = ({
  selectedUser,
  setSelectedUser,
}: SearchUsersComponentProps) => {
  const [searchParams, setSearchParams] = useState({
    location: { lat: '', lon: '' },
    radius: SEARCH_RADIUS_IN_METERS,
  })
  const {
    data: users,
    isLoading,
    error,
  } = useGetUsersAroundPoint({
    latitude: Number(searchParams.location.lat),
    longitude: Number(searchParams.location.lon),
    radius: searchParams.radius,
  })

  return (
    <Grid templateColumns="4fr 3fr" gap={12} w="full">
      <GridItem>
        <VStack spacing={4} w="full" h="full">
          <AddressInput
            onLocationSelect={(location) =>
              setSearchParams({ ...searchParams, location })
            }
            label="City or address"
            fullAddressOnly={false}
          />
          <MapView
            searchParams={searchParams}
            users={users ?? []}
            selectedUser={selectedUser}
          />
        </VStack>
      </GridItem>
      <GridItem>
        {isLoading && <Spinner />}
        {error && <Text color="red.500">Error loading users</Text>}
        <SuggestedCorrespondents
          selectedUser={selectedUser}
          users={users ?? []}
          onSelectUser={setSelectedUser}
        />
      </GridItem>
    </Grid>
  )
}

export default SearchUsersComponent
