import People from '../assets/svg/people'
import Home from '../assets/svg/home'
import Tools from '../assets/svg/tools'

export const menuLink = [
  {
    ariaLabel: 'home',
    icon: <Home />,
    href: '/home',
  },
  {
    ariaLabel: 'people',
    icon: <People />,
    href: '/patients',
  },
  {
    ariaLabel: 'my-info',
    icon: <Tools />,
    href: '/my-info',
  },
]
