import { IAnalysisImage } from '../../../types/diagnostic-image'
import { useGet } from '../../query/useGet'

export function useGetSharedDiagnosticImages(diagnosticId: string) {
  const images = useGet<IAnalysisImage[]>(
    `diagnostics/${diagnosticId}/diagnostic-images`
  )

  return images
}
