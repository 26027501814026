import {
  Heading,
  Flex,
  VStack,
  Box,
  Text,
  Center,
  Button,
} from '@chakra-ui/react'
import SharedCase from '../SharedCaseCard'
import { useGetInProgressSharedDiagnostics } from '../../api/api_hooks/shared-diagnostic'
import { ISharedDiagnostic } from '../../types/diagnostics'
import Plus from '../../assets/svg/plus'
import { useNavigate } from 'react-router-dom'

export default function InProgressRequests() {
  const { data, isLoading, refetch } = useGetInProgressSharedDiagnostics()
  const navigate = useNavigate()

  const NoRequestsMessage = () => (
    <Center h="100%">
      <Text color="gray.500">No requests in progress</Text>
    </Center>
  )

  const inProgressCases = data?.filter(
    (sharedCase: ISharedDiagnostic) => sharedCase.status === 'in_process'
  )

  const navigateWithState = () => {
    navigate('/patients?newRequest=true')
  }

  return (
    <VStack align="stretch" spacing={6} height="100%" overflow="hidden" py={4}>
      <Flex
        alignItems="center"
        flexShrink={0}
        justify="space-between"
        width="100%"
      >
        <Heading size="md">In Progress Requests</Heading>
        <Button
          onClick={navigateWithState}
          leftIcon={<Plus />}
          colorScheme="purple"
        >
          Add request
        </Button>
      </Flex>

      <Box flex={1} overflowY="auto">
        {isLoading ? (
          <Center h="100%">
            <Text>Loading...</Text>
          </Center>
        ) : !data || !inProgressCases?.length ? (
          <NoRequestsMessage />
        ) : (
          <VStack align="stretch" gap={5}>
            {inProgressCases.map((sharedCase: ISharedDiagnostic) => (
              <SharedCase
                key={sharedCase.id}
                data={sharedCase}
                reloadReceivedSharedDiagnostics={refetch}
              />
            ))}
          </VStack>
        )}
      </Box>
    </VStack>
  )
}
