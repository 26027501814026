// === USERS Constants ===
export const SEARCH_RADIUS_IN_METERS = 10000

// === SHARED DIAGNOSTIC Constants ===
export const sharedDiagnosticStatusColors = {
  in_process: 'green',
  closed: 'gray',
  expired: 'red',
}
export const receiverStatusColors = {
  pending: 'yellow',
  accepted: 'green',
  declined: 'red',
  expired: 'gray',
}

// === ANALYSIS Constants ===
// === MALARIA Constants ===
export const PARASITEMIA_THRESHOLD = 5
export const ANALYZED_CELLS_THRESHOLD = 10000
