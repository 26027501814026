import { useState, useEffect } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useGetSharedDiagnosticById } from '../../../../api/api_hooks/shared-diagnostic'
import { RefObject } from 'react'
import { AnalysisTypeEnum } from '../../../../types/ia-analysis'
import {
  AnalysisImageProcessEnum,
  IAnalysisImage,
} from '../../../../types/diagnostic-image'
import ProcessingMsg from '../../../_shared/ProcessingMsg'
import AlertBeforeStartAnalysis from '../AlertBeforeStartAnalysis'
import TogglePositiveCells from './components/TogglePositiveCells'
import AiModelsMenuList from './components/AiModelsMenuList'
import CropButton from './components/CropButton'
import ZoomButtons from './components/ZoomButtons'

interface ToolsButtonsProps {
  setZoomIn: (value: boolean) => void
  setCropToggle: (value: boolean) => void
  cropToggle: boolean
  swiperRef: RefObject<any>
  photoData: IAnalysisImage[]
  showAnalysisImagesZone: boolean
  setShowAnalysisImagesZone: (value: boolean) => void
  refetchDiagnosticImages?: () => void
}

const ToolsButtons = ({
  setZoomIn,
  setCropToggle,
  cropToggle,
  swiperRef,
  photoData,
  showAnalysisImagesZone,
  setShowAnalysisImagesZone,
  refetchDiagnosticImages,
}: ToolsButtonsProps) => {
  const isDiagnosticInProgress =
    useGetSharedDiagnosticById().data?.status === 'in_process'
  const [activeIndex, setActiveIndex] = useState(0)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [selectedAnalysisType, setSelectedAnalysisType] =
    useState<AnalysisTypeEnum | null>(null)

  useEffect(() => {
    const swiper = swiperRef.current?.swiper
    if (swiper) {
      setActiveIndex(swiper.activeIndex)

      const handleSlideChange = () => {
        setActiveIndex(swiper.activeIndex)
      }

      swiper.on('slideChange', handleSlideChange)

      return () => {
        swiper.off('slideChange', handleSlideChange)
      }
    }
  }, [swiperRef])

  const imgHasSuccessAnalysis =
    photoData?.[activeIndex]?.process === AnalysisImageProcessEnum.Success
  const imgHasInProcessAnalysis =
    photoData?.[activeIndex]?.process === AnalysisImageProcessEnum.InProgress
  const imgHasNotAnalysis =
    photoData?.[activeIndex]?.process === AnalysisImageProcessEnum.NotStarted ||
    photoData?.[activeIndex]?.process === AnalysisImageProcessEnum.Failed
  const imgHasFailedAnalysis =
    photoData?.[activeIndex]?.process === AnalysisImageProcessEnum.Failed

  return (
    <Box display={'flex'} justifyContent={'space-between'} gap={5} mb={3}>
      <>
        {isDiagnosticInProgress && imgHasNotAnalysis && (
          <AiModelsMenuList
            setSelectedAnalysisType={setSelectedAnalysisType}
            setIsAlertOpen={setIsAlertOpen}
            imgHasFailedAnalysis={imgHasFailedAnalysis}
          />
        )}
        {imgHasSuccessAnalysis && (
          <TogglePositiveCells
            showAnalysisImagesZone={showAnalysisImagesZone}
            setShowAnalysisImagesZone={setShowAnalysisImagesZone}
          />
        )}
        {imgHasInProcessAnalysis && <ProcessingMsg />}
      </>
      <Flex gap={8} flex={1} justifyContent={'flex-end'}>
        {isDiagnosticInProgress && (
          <CropButton cropToggle={cropToggle} setCropToggle={setCropToggle} />
        )}
        <ZoomButtons setZoomIn={setZoomIn} swiperRef={swiperRef} />
      </Flex>
      <AlertBeforeStartAnalysis
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        refetchDiagnosticImages={refetchDiagnosticImages}
        selectedAnalysisType={selectedAnalysisType}
        setSelectedAnalysisType={setSelectedAnalysisType}
        photoData={photoData}
        activeIndex={activeIndex}
      />
    </Box>
  )
}

export default ToolsButtons
