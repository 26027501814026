import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import { useState } from 'react'

interface IPatientData {
  title: string
  info: string | undefined
  iconCopy?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

export default function PatientInfoItem({
  title,
  info,
  iconCopy,
}: IPatientData) {
  const [isCopy, setIsCopy] = useState(false)

  const makeCopy = (text: string) => {
    navigator.clipboard.writeText(text)
    setIsCopy(true)
  }

  return (
    <Flex alignItems={'center'}>
      <Box w={'40%'}>
        <Text fontSize="sm" color={'brand.lightGrayText'} mr={2}>
          {title}
        </Text>
      </Box>
      <Box w={'60%'} display={'flex'} alignItems={'center'}>
        <Text fontSize="sm">{iconCopy ? info?.slice(0, 6) : info}</Text>
        {iconCopy && info && (
          <Tooltip
            label={isCopy ? 'Copied' : 'Copy'}
            onClose={() => setIsCopy(false)}
            closeDelay={1000}
          >
            <IconButton
              onClick={() => makeCopy(info)}
              colorScheme="#999FA9"
              aria-label="Copy ID"
              icon={iconCopy}
            />
          </Tooltip>
        )}
      </Box>
    </Flex>
  )
}
