import { Box, Heading, Text, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading display="inline-block" size="2xl">
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
        The page you are looking for does not exist
      </Text>
      <Button as={Link} to="/home" color="white" bg="brand.purple">
        Back to home
      </Button>
    </Box>
  )
}
