import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import ControlInput from '../../Forms/components/ControlInput'
import { useMyProfile } from '../../../api/api_hooks/user/myProfile'
import { useCloseSharedDiagnostic } from '../../../api/api_hooks/shared-diagnostic'
import { useUpdateProfile } from '../../../api/api_hooks/user/useUpdateProfile'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CloseRequestDoctorNumbers } from '../../../utils/schema'
import { AxiosError } from 'axios'

type Inputs = {
  adeliFinessNumber: string
  rppsNumber: string
}

interface FinalizeModalProps {
  isOpen: boolean
  onClose: () => void
  refetchSharedDiagnostic: () => Promise<any>
}

const FinalizeModal = ({
  isOpen,
  onClose,
  refetchSharedDiagnostic,
}: FinalizeModalProps) => {
  const { data: userData } = useMyProfile()
  const { mutateAsync: closeSharedDiagnostic } = useCloseSharedDiagnostic()
  const { mutateAsync: updateProfile } = useUpdateProfile()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(CloseRequestDoctorNumbers),
  })
  const toast = useToast()

  useEffect(() => {
    if (userData) {
      setValue('adeliFinessNumber', userData.adeliFinessNumber || '')
      setValue('rppsNumber', userData.rppsNumber || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      // if fields are different from the ones in the form, we need to create a new FormData object
      const fieldsAreDifferent =
        data.adeliFinessNumber !== userData?.adeliFinessNumber ||
        data.rppsNumber !== userData?.rppsNumber
      if (fieldsAreDifferent) {
        const formData = new FormData()
        formData.append('adeliFinessNumber', data.adeliFinessNumber)
        formData.append('rppsNumber', data.rppsNumber)

        await updateProfile(formData as unknown as void)
      }
      closeRequest()
    } catch (error) {
      toast({
        title: `Error updating profile`,
        description: (error as AxiosError).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const closeRequest = async () => {
    await closeSharedDiagnostic()
    await refetchSharedDiagnostic()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent fontFamily={'Poppins'} py={2}>
          <ModalHeader>
            <Text fontSize={'xl'}>Close request</Text>
            <Text
              fontWeight={400}
              color={'brand.lightGrayText'}
              fontSize={'sm'}
            >
              Your report and invoice will appear in the next step
            </Text>
          </ModalHeader>
          <ModalBody gap={8}>
            <ControlInput
              register={{ ...register('adeliFinessNumber') }}
              label="Adeli/FINESS Number"
              placeholder="XXXXXXXXX"
              isInvalid={!!errors.adeliFinessNumber}
              errorMessage={
                errors.adeliFinessNumber && errors.adeliFinessNumber.message
              }
            />
            <ControlInput
              register={{ ...register('rppsNumber') }}
              label="RPPS Number"
              placeholder="XXXXXXXXXXX"
              isInvalid={!!errors.rppsNumber}
              errorMessage={errors.rppsNumber && errors.rppsNumber.message}
            />
          </ModalBody>
          <ModalFooter
            display={'flex'}
            justifyContent={'space-between'}
            gap={4}
            mt={12}
          >
            <Button onClick={onClose} variant={'outline'} w={'100%'}>
              Cancel
            </Button>
            <Button colorScheme="purple" w={'100%'} type="submit">
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default FinalizeModal
