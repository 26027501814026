import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { usePostAnalysis } from '../../../api/api_hooks/analysisMalaria'
import { AnalysisTypeEnum, IAnalysis } from '../../../types/ia-analysis'
import { IAnalysisImage } from '../../../types/diagnostic-image'

interface IAlertBeforeStartAnalysisProps {
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  refetchDiagnosticImages?: () => void
  selectedAnalysisType: AnalysisTypeEnum | null
  setSelectedAnalysisType: (value: AnalysisTypeEnum | null) => void
  photoData: IAnalysisImage<IAnalysis>[]
  activeIndex: number
}

const AlertBeforeStartAnalysis = ({
  isAlertOpen,
  setIsAlertOpen,
  refetchDiagnosticImages,
  selectedAnalysisType,
  setSelectedAnalysisType,
  photoData,
  activeIndex,
}: IAlertBeforeStartAnalysisProps) => {
  const { mutateAsync: processAnalysis } = usePostAnalysis()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const toast = useToast()
  const startAnalysis = async (type: AnalysisTypeEnum) => {
    toast({
      title: 'Analysis in progress',
      description: 'Please wait for results.',
      status: 'info',
      duration: 5000,
      position: 'top',
      isClosable: true,
    })
    await processAnalysis({
      diagnosticImageId: photoData?.[activeIndex]?.id,
      analysisType: type,
    })
    refetchDiagnosticImages && refetchDiagnosticImages()
  }

  const handleConfirmAnalysis = async () => {
    setIsAlertOpen(false)
    if (selectedAnalysisType) {
      await startAnalysis(selectedAnalysisType)
    }
  }

  const handleCancelAnalysis = () => {
    setIsAlertOpen(false)
    setSelectedAnalysisType(null)
  }
  return (
    <AlertDialog
      isOpen={isAlertOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleCancelAnalysis}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Start{' '}
            {selectedAnalysisType
              ? selectedAnalysisType.charAt(0).toUpperCase() +
                selectedAnalysisType.slice(1)
              : ''}{' '}
            Analysis
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to start the {selectedAnalysisType} analysis?
            This process may take a few minutes.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleCancelAnalysis}>
              Cancel
            </Button>
            <Button colorScheme="purple" onClick={handleConfirmAnalysis} ml={3}>
              Start Analysis
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default AlertBeforeStartAnalysis
