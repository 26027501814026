import { Divider, Flex } from '@chakra-ui/react'
import { ISharedDiagnostic } from '../../../../types/diagnostics'
import PatientInfo from './PatientInfo'
import Header from './Header'
import SharedDiagnosticInfo from './SharedDiagnosticInfo'
import { useGetPatient } from '../../../../api/api_hooks/patients'

export default function SidBarInfo({
  diagnosticData,
}: {
  diagnosticData: ISharedDiagnostic | null
}) {
  const { data: patientData } = useGetPatient()

  return (
    <Flex
      p={'32px 16px'}
      bg={'white'}
      direction={'column'}
      height={'100%'}
      position={'sticky'}
      fontFamily={'Poppins'}
    >
      {patientData && (
        <>
          <Header patientData={patientData} />
          <Divider my={4} />
          <PatientInfo patientData={patientData} />
          {diagnosticData && (
            <SharedDiagnosticInfo diagnosticData={diagnosticData} />
          )}
        </>
      )}
    </Flex>
  )
}
