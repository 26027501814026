import { Card, CardBody, CardHeader } from '@chakra-ui/react'
import { ISharedDiagnostic } from '../../../types/diagnostics'
import SwiperSlider from '../../SwiperSlider'
import HeaderWhiteBoardCard from './HeaderWhiteBoardCard'
import { IAnalysisImage } from '../../../types/diagnostic-image'

interface WhiteBoardCardProps {
  sharedDiagnostic: ISharedDiagnostic
  onOpen: () => void
  diagnosticImages: IAnalysisImage[]
  refetchDiagnosticImages: () => void
}

const WhiteBoardCard = ({
  sharedDiagnostic,
  onOpen,
  diagnosticImages,
  refetchDiagnosticImages,
}: WhiteBoardCardProps) => {
  return (
    <Card
      fontFamily={'Poppins'}
      w={'100%'}
      mt={6}
      px={4}
      py={2}
      maxW="100%"
      overflow="hidden"
      borderRadius={18}
    >
      <CardHeader>
        <HeaderWhiteBoardCard
          sharedDiagnostic={sharedDiagnostic}
          onOpen={onOpen}
        />
      </CardHeader>
      <CardBody>
        <SwiperSlider
          photoData={diagnosticImages ?? []}
          refetchDiagnosticImages={refetchDiagnosticImages}
        />
      </CardBody>
    </Card>
  )
}

export default WhiteBoardCard
