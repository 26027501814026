import { Flex, Switch, Text } from '@chakra-ui/react'

const TogglePositiveCells = ({
  showAnalysisImagesZone,
  setShowAnalysisImagesZone,
}: {
  showAnalysisImagesZone: boolean
  setShowAnalysisImagesZone: (value: boolean) => void
}) => {
  return (
    <Flex alignItems="center">
      <Switch
        isChecked={showAnalysisImagesZone}
        onChange={() => setShowAnalysisImagesZone(!showAnalysisImagesZone)}
        colorScheme="purple"
        size="md"
        mr={2}
      />
      <Text
        fontSize="sm"
        fontWeight="medium"
        color={showAnalysisImagesZone ? 'purple.500' : 'gray.600'}
      >
        Show AI positive cells
      </Text>
    </Flex>
  )
}

export default TogglePositiveCells
