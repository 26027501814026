import { RepeatIcon } from '@chakra-ui/icons'
import {
  Menu,
  Box,
  MenuButton,
  IconButton,
  Flex,
  MenuList,
  MenuGroup,
  MenuItem,
  Image,
} from '@chakra-ui/react'
import { AnalysisTypeEnum } from '../../../../../types/ia-analysis'
import AiIcon from '../../../../../assets/AiIcon.png'

const AiModelsMenuList = ({
  setSelectedAnalysisType,
  setIsAlertOpen,
  imgHasFailedAnalysis,
}: {
  setSelectedAnalysisType: (value: AnalysisTypeEnum | null) => void
  setIsAlertOpen: (value: boolean) => void
  imgHasFailedAnalysis: boolean
}) => {
  const handleAnalysisClick = (type: AnalysisTypeEnum) => {
    setSelectedAnalysisType(type)
    setIsAlertOpen(true)
  }
  return (
    <Menu placement="right-end">
      <Box position="relative">
        <MenuButton
          as={IconButton}
          aria-label="ia-btn"
          icon={<Image src={AiIcon} alt="IA Icon" boxSize="32px" />}
          variant={'outline'}
          colorScheme="purple"
          borderColor={'brand.lightGrayBorder'}
        />
        {imgHasFailedAnalysis && (
          <Flex
            position="absolute"
            top="-8px"
            right="-8px"
            bg={'white'}
            color="brand.blue"
            borderRadius="full"
            w="20px"
            h="20px"
            justifyContent="center"
            alignItems="center"
          >
            <RepeatIcon />
          </Flex>
        )}
      </Box>
      <MenuList>
        <MenuGroup
          title="Choose a model"
          fontSize="sm"
          fontWeight="medium"
          color={'brand.lightGrayText'}
          mx={3}
        >
          <MenuItem
            onClick={() => handleAnalysisClick(AnalysisTypeEnum.MALARIA)}
          >
            Malaria analysis
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default AiModelsMenuList
